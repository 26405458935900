import PropTypes from 'prop-types';
import clsx from 'clsx';
import prepareTranslate from '@/helpers/dictionary';
import {
  Checkbox,
  Skeleton,
  Table,
} from '@mantine/core';
import NoData from '@/components/Chart/noData';
import ColumnContent from '../ColumnContent';
import styles from './style.module.css';

const CHECKBOX_COLUMN_WIDTH = '50px';

const Rows = (props) => {
  const {
    data = [],
    columns = [],
    loading = false,
    isAllowCheck = false,
    setSelection = () => {},
    selection = [],
    noDataAction,
  } = props;

  const t = prepareTranslate();

  const columnsVisible = columns.filter((c) => (
    c.isVisible === undefined
    || c.isVisible === true
  ));
  const loadingRow = <Table.Tr>
    {columnsVisible.map((_c, index) => <Table.Td key={index}><Skeleton height="1rem">&nbsp;</Skeleton></Table.Td>)}
  </Table.Tr>;

  const handleCheckboxChange = (e) => {
    const checkbox = e.target;
    const { id } = checkbox.dataset;

    setSelection((current) => (
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    ));
  };

  const output = data.map((item) => {
    const id = item.id || item._id;
    const selected = selection.includes(id);
    const rowClassnames = clsx(styles.row, selected && styles.rowselected);

    const rowData = columns.map((column) => {
      const {
        header,
        accessor,
        isVisible = true,
        actions,
        transforms = [],
      } = column;

      const key = `${accessor}${header}`;

      return (isVisible)
        ? <Table.Td key={key}>
            <ColumnContent
              accessor={accessor}
              item={item}
              actions={actions}
              transforms={transforms}
            />
          </Table.Td>
        : null;
    });

    const checkColumn = () => {
      if (!isAllowCheck || loading) return null;

      return (
        <Table.Td style={{ width: CHECKBOX_COLUMN_WIDTH }}>
          <Checkbox
            data-id={id}
            checked={selection.includes(id)}
            onChange={handleCheckboxChange}
          />
        </Table.Td>
      );
    };

    return (
      <Table.Tr key={id} className={rowClassnames}>
        {checkColumn()}
        {rowData}
      </Table.Tr>
    );
  });

  if (loading) {
    return <>{loadingRow}{loadingRow}{loadingRow}</>;
  }

  if (!output.length) {
    return (
    <Table.Tr>
      <Table.Td colSpan={columnsVisible.length}>
          <NoData noDataAction={noDataAction} title={t('components.rows.empty.title')} />
      </Table.Td>
    </Table.Tr>
    );
  }

  return output;
};

Rows.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  isAllowCheck: PropTypes.bool,
  setSelection: PropTypes.func,
  selection: PropTypes.array,
  noDataAction: PropTypes.string,
};

export default Rows;
