import docsMutation from '@/api/mutitations/docs';
import PageTemplate from '@/components/PageTemplate';
import useAccessToken from '@/hooks/useAccessToken';
import Table from '@/components/Table';
import ChartComponent from '@/components/Chart';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import agentQuery from '@/api/queries/agent';
import { isObjectEmpty } from '@/helpers/is';
import companyQuery from '@/api/queries/company';
import productQuery from '@/api/queries/product';
import exportRuleQuery from '@/api/queries/exportRule';
import { downloadFile } from '@/helpers/download';
import { notifications } from '@mantine/notifications';
import prepareTranslate from '@/helpers/dictionary';
import { IconUserCheck, IconCalendarEvent, IconBuildingSkyscraper } from '@tabler/icons-react';

const ReportView = () => {
  const t = prepareTranslate();
  useAccessToken();

  const [identifierId, setIdentifierId] = useState();
  const [columns, setColumns] = useState([]);
  const [noIdentifiers, setNoIdentifiers] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedProducts, setselectedProducts] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const { id: reportId } = useParams();
  // eslint-disable-next-line max-len
  const { data: exportRuleData, isFetching: exportRuleFetching } = exportRuleQuery.useFindById({ id: reportId });
  const { data: companyData } = companyQuery.useGetAll();
  const { data: productData } = productQuery.useGetAll();
  const { mutateAsync: chartMutateAsync } = docsMutation.useExportChart();
  const { mutateAsync: tableMutateAsync } = docsMutation.useExportTable();
  const { mutateAsync: exportFileMutate } = docsMutation.useExportFile();
  // eslint-disable-next-line max-len
  const { data: agentData, isFetching: agentFetching } = agentQuery.useGetAgentById({ id: identifierId });

  const [sortCategory, setSortCategory] = useState('');
  const showChartType = sortCategory === 'date';

  const childProps = {
    isChartTypeActive: showChartType,
    noDataAction: 'addFiles',
  };

  // eslint-disable-next-line consistent-return
  const getChartData = async (query) => {
    let returnData;

    if (!isObjectEmpty(query)) {
      const {
        agent,
        year,
        month,
        company,
        product,
        category,
      } = query;

      setSortCategory(category);
      const seriesFormat = { name: [] };
      let queryData;
      if (category === 'date') {
        const firstMonth = month[0];
        const lastMonth = month[month.length - 1];
        const firstYear = year[0];
        const lastYear = year[year.length - 1];
        setSelectedDate(`${firstMonth}/${firstYear} - ${lastMonth}/${lastYear} `);
      } else {
        setSelectedDate(`${month}/${year} `);
      }

      if (company.length !== 0) {
        seriesFormat.name.unshift('company');
        setSelectedCompany(company);
      }

      if (product.length !== 0) {
        seriesFormat.name.unshift('product');
        setselectedProducts(product);
      }

      if (agent.length > 1) {
        seriesFormat.name.unshift({ name: 'personalDetails', value: ['uid', 'fullName'] });
      } else if (agent.length === 0) {
        setNoIdentifiers(true);
      } else {
        setIdentifierId(agent);
      }

      if (seriesFormat.name.length === 0) {
        seriesFormat.name.push('primaryValue');
      }

      queryData = {
        year,
        month,
        identifiers: agent,
        query: {},
        additionalData: {
          companyId: company,
          productId: product,
        },
        categoriesType: category,
      };

      queryData = { exportRuleId: reportId, seriesFormat, ...queryData };

      try {
        returnData = await chartMutateAsync(queryData);
      } catch {
        returnData = { data: [], series: [] };
      }

      return returnData;
    }
  };

  const getTableData = async (start, end, query) => {
    let returnData = { data: [], series: [] };
    const { agent, year, month } = query;

    const queryData = {
      year,
      month,
      identifiers: agent,
      query: {},
      additionalData: {
        companyId: query.company,
        productId: query.product,
      },
      exportRuleId: reportId,
    };

    try {
      const { data: mutateData, count } = await tableMutateAsync(queryData);
      const { rows, columns: dataColumns } = mutateData;

      setColumns(dataColumns);
      returnData = { data: rows, count };
    } catch {
      returnData = { data: [], count: 0 };
    }

    return returnData;
  };

  const handleExport = async (option, query) => {
    const { agent, year, month } = query;

    const exportData = {
      columns,
      query: {},
      fileType: option,
      service: 'docs',
      titleArray: ['personalDetails.fullName'],
      furtherData: {
        identifiers: agent,
        additionalData: {
          companyId: query.company,
          productId: query.product,
        },
        exportRuleId: reportId,
        year,
        month,
      },
    };

    const fileTitleBase = exportRuleData?.title;
    const fileTitle = option === 'pdf' ? `${fileTitleBase}.pdf` : `${fileTitleBase}.xlsx`;

    try {
      const fileData = await exportFileMutate(exportData);

      downloadFile(fileData, fileTitle);
    } catch (error) {
      notifications.show({
        title: t('pages.agents.notification.errortitle'),
        message: t('pages.agents.notification.errormessage'),
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching users:', error);
    }
  };
  const fullName = noIdentifiers ? t('pages.reportView.allAgents') : agentData?.personalDetails?.fullName;

  const pageHeader = `${exportRuleData?.title}`;
  const companyName = selectedCompany?.map((id) => companyData?.find((company) => company._id === id)?.hebrew)?.filter(Boolean).join(', ') || t('pages.reportView.allCompanies');
  const productName = selectedProducts?.map((id) => productData?.find((product) => product._id === id)?.hebrew)?.filter(Boolean).join(', ') || t('pages.reportView.allProducts');
  const companyAndProductsubtitle = <><IconBuildingSkyscraper />{t('pages.reportView.companySubtitle', [companyName, productName]) }</>;
  const agentSubitle = <><IconUserCheck />{t('pages.reportView.agentSubtitle', [fullName]) }</>;
  const dateSubtitle = <><IconCalendarEvent />{selectedDate}</>;

  useEffect(() => {
    const columnsCopy = columns;
    const companySelect = companyData && companyData
      .map((company) => ({ label: company.hebrew, value: company._id }));
    const productSelect = productData && productData
      .map((product) => ({ label: product.hebrew, value: product._id }));
    const companyColumnIndex = columnsCopy.findIndex((column) => column.accessor === 'company');
    columnsCopy[companyColumnIndex] = { data: companySelect, ...columnsCopy[companyColumnIndex] };
    const productColumnIndex = columnsCopy.findIndex((column) => column.accessor === 'product');
    columnsCopy[productColumnIndex] = { data: productSelect, ...columnsCopy[productColumnIndex] };
    setColumns(columnsCopy);
  }, [companyData, productData, columns]);

  return (
    <>

      <PageTemplate title={(!exportRuleFetching && !agentFetching) && pageHeader}
        subtitle={[companyAndProductsubtitle, agentSubitle, dateSubtitle]}
       >
         <Table
          isExportActive
          exportOptions={['excel']}
          getData={getTableData}
          columns={columns}
          onExport={handleExport}
          isColumnsActive={false}
          childElement={ChartComponent}
          childGetData={getChartData}
          isFilterActive={false}
          childProps={childProps}
          noDataAction='addFiles'
        />
      </PageTemplate>
    </>
  );
};

export default ReportView;
