import React from 'react';
import ReactDOM from 'react-dom/client';
import consoleConfig from '@/config/console';
import enableMocking from '@/config/mocks';
import App from './App';
import './config/sentry';

// eslint-disable-next-line
console.log(`%cApp version: ${__VERSION__} (${import.meta.env.MODE})`, 'color:green');

consoleConfig.start();

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});
