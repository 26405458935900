import agentMutations from '@/api/mutitations/agent';
import agentQuery from '@/api/queries/agent';
import companyQuery from '@/api/queries/company';
import productQuery from '@/api/queries/product';
import prepareTranslate from '@/helpers/dictionary';
import { ACCORD_TYPE, PROCESS_NOTIFICATION_SCREENS } from '@/helpers/enums';
import {
  Autocomplete,
  Center,
  Loader,
  LoadingOverlay,
  Text,
} from '@mantine/core';
import { useDebouncedCallback, useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CreateAgentClientInProcess from '../CreateAgentClientInProcess';
import CreateAgentAgreementInProcess from '../CreateAgentAgreementInProcess';

function CreateClientOrAgreement(props) {
  const {
    form,
    currentIdentifier,
    createdAgent,
    clientOrAgreement,
  } = props;

  const {
    year,
    month,
    identifierNumber,
    company: identifierCompany,
    product: identifierProduct,
  } = currentIdentifier;

  const t = prepareTranslate();

  const [autoCompleteValue, setAutoCompleteValue] = useState(createdAgent?.string);
  const [pickedAgentId, setPickedAgentId] = useState(createdAgent?.id);
  const [autoCompleteData, setAutoCompleteData] = useState([]);

  const { data: products } = productQuery.useGetAll();
  const { data: companies } = companyQuery.useGetAll();
  const { data: agentData, status, isFetching } = agentQuery.useGetAgentById({ id: pickedAgentId });
  const { mutateAsync: agentMutate } = agentMutations.useGetAgents();

  const [autoSelectLoading, { open: autoSelectOpen, close: autoSelectClose }] = useDisclosure();

  const productsOptions = products?.map((product) => ({
    label: product.hebrew,
    value: product._id,
  }));

  const companiesOptions = companies?.map((company) => ({
    label: company.hebrew,
    value: company._id,
  }));

  const autoCompleteQuery = useDebouncedCallback(async (val) => {
    autoSelectOpen();

    if (val === '') {
      setAutoCompleteData([]);
    } else {
      const start = 1;
      const end = 100;
      const query = { 'personalDetails.fullName': val };

      try {
        const { data: agentsData } = await agentMutate({ query, start, end });

        if (agentsData.length === 0) {
          setAutoCompleteData([]);
        } else {
          setAutoCompleteData(agentsData.map((agent) => ({ label: `${agent.personalDetails.fullName} (${agent.personalDetails.uid})`, value: agent._id })));
        }
      } catch {
        notifications.show({
          title: t('pages.reports.notification.error.title'),
          message: t('pages.reports.notification.error.message'),
          color: 'red',
        });
      }
    }

    autoSelectClose();
  }, 500);

  const handleAutocompleteChange = (val) => {
    if (pickedAgentId) {
      setAutoCompleteValue('');
      setAutoCompleteData([]);
      setPickedAgentId(null);
    } else {
      const hasAgent = autoCompleteData.find((agent) => agent.label === val);
      setAutoCompleteValue(val);

      if (!hasAgent) {
        autoCompleteQuery(val);
      } else {
        setAutoCompleteData([]);
      }
    }
  };

  const handleOptionSubmit = (val) => {
    setPickedAgentId(val);
  };

  useEffect(() => {
    const agreementsSanitizedData = agentData?.agreements?.map((item) => {
      const itemCopy = item;
      itemCopy.from = item.from ? new Date(item.from) : null;
      itemCopy.till = item.till ? new Date(item.till) : null;

      itemCopy.companyId = item.companyId?._id;
      itemCopy.branchId = item.branchId.map((branch) => branch?._id);

      return itemCopy;
    });

    const clientsSanitizedData = agentData?.clients.map((item) => {
      const itemCopy = item;
      itemCopy.companyId = item.companyId?._id;
      itemCopy.branchId = item.branchId?._id;
      return itemCopy;
    });

    form.setValues({
      id: pickedAgentId,
      personalDetails: agentData?.personalDetails,
      agreements: agreementsSanitizedData,
      status: agentData?.status,
      clients: clientsSanitizedData,
      agentType: agentData?.agentType,
      employeeType: agentData?.employeeType,
    });

    if (clientOrAgreement === PROCESS_NOTIFICATION_SCREENS.createAgreement) {
      // Add empty agreement
      form.insertListItem('agreements', {
        accordType: ACCORD_TYPE[0].value,
        agentNumber: identifierNumber,
        from: new Date(year, month - 1),
        till: null,
        companyId: identifierCompany.id,
        branchId: [identifierProduct.id],
      });
    } else {
      form.insertListItem('clients', {
        accordType: ACCORD_TYPE[0].value,
        clientNumber: identifierNumber,
        companyId: identifierCompany.id,
        branchId: identifierProduct.id,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData, status]);

  // eslint-disable-next-line max-len
  const clientTerm = pickedAgentId && (clientOrAgreement === PROCESS_NOTIFICATION_SCREENS.createClient);
  // eslint-disable-next-line max-len
  const agreementTerm = pickedAgentId && (clientOrAgreement === PROCESS_NOTIFICATION_SCREENS.createAgreement);
  const clientOrAgreementTitle = clientOrAgreement === PROCESS_NOTIFICATION_SCREENS.createClient ? t('components.processNotification.popUp.createClient')
    : t('components.processNotification.popUp.createAgreement');

  return (
    <>
    <Center>
        <Text
          size='xl'
          fw='bold'
        >
         {clientOrAgreementTitle}
        </Text>
        </Center>
      <LoadingOverlay visible={isFetching} />
      <Center mb='lg'>
        <Autocomplete
          label={t('pages.reports.form.agent')}
          rightSection={autoSelectLoading ? <Loader size="1rem" /> : null}
          data={autoCompleteData}
          limit="10"
          key={form.key('agent')}
          {...form.getInputProps('agent')}
          value={autoCompleteValue}
          onChange={handleAutocompleteChange}
          onOptionSubmit={handleOptionSubmit}
          w='300px'
        />
      </Center>
      {
        clientTerm && <CreateAgentClientInProcess
          form={form}
          productsOptions={productsOptions}
          companiesOptions={companiesOptions}
          agentData={agentData}
          pickedAgentId={pickedAgentId}
          currentIdentifier={currentIdentifier}
        />
      }
      {
        agreementTerm && <CreateAgentAgreementInProcess
          form={form}
          productsOptions={productsOptions}
          companiesOptions={companiesOptions}
          agentData={agentData}
          currentIdentifier={currentIdentifier}
        />
      }
    </>
  );
}

CreateClientOrAgreement.propTypes = {
  form: PropTypes.shape({
    setValues: PropTypes.func.isRequired,
    insertListItem: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
    key: PropTypes.func.isRequired,
    getInputProps: PropTypes.func.isRequired,
  }).isRequired,
  clientOrAgreement: PropTypes.number,
  currentIdentifier: PropTypes.shape({
    year: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    identifierNumber: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  createdAgent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    string: PropTypes.string.isRequired,
  }),
};

export default CreateClientOrAgreement;
