import PropTypes from 'prop-types';
import {
  Center,
  Table,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';
import { DIRECTIONS } from '@/helpers/enums';
import styles from './styles.module.css';

const Th = (props) => {
  const {
    children,
    sortAction = {},
    accessor,
    onSort,
    style,
    isActive = true,
    sort,
  } = props;

  let Icon = IconSelector;
  if (accessor === sortAction.title && sortAction.direction !== null) {
    Icon = (sortAction.direction === DIRECTIONS.asc) ? IconChevronDown : IconChevronUp;
  }
  const handleSortClick = () => {
    const direction = (sortAction.direction === DIRECTIONS.asc)
      ? DIRECTIONS.desc
      : DIRECTIONS.asc;

    onSort({ title: accessor, direction, sort });
  };

  return (isActive)
    ? (<Table.Th className={styles.th} style={style}>
        <UnstyledButton
         onClick={handleSortClick}
         className={styles.control}
         disabled={!sort}
        >
          <Text fw={500} fz="sm">{children}</Text>
          <Center className={styles.icon}>
           {sort && (<Icon style={{ width: '1rem', height: '1rem' }} stroke={1.5} />)}
          </Center>
        </UnstyledButton>
      </Table.Th>)
    : (<Table.Th className={styles.th} style={style}>
        <Text fw={500} fz="sm">{children}</Text>
      </Table.Th>);
};

Th.propTypes = {
  children: PropTypes.any,
  sortAction: PropTypes.object,
  sort: PropTypes.object,
  accessor: PropTypes.string,
  onSort: PropTypes.func,
  style: PropTypes.object,
  isActive: PropTypes.bool,
};

export default Th;
