export default {
  title: 'צפייה בעמלות סוכנות',
  action: 'ערוך עמלות סוכנות',
  details: {
    company: 'חברה מנהלת',
    product: 'מוצר',
    agencyFeeMonthlyDeposit: 'עמלת סוכנות נפרעים מהפקדה %',
    agencyFeeMonthlyAccumulation: 'עמלת סוכנות נפרעים מצבירה %',
    agencyFeeAnnualAccumulation: 'עמלת סוכנות היקף מצבירה %',
    agencyFeeAnnualDeposit: 'עמלת סוכנות היקף מהפקדה %',
    areFeesIncludingVAT: 'האם העמלות כוללות מע״מ',
  },
  side: {
    tabs: 'מידע נוסף',
    comments: 'הערות',
    histories: 'היסטוריה',
    actions: 'פעולות',
  },
};
