import { useQuery } from '@tanstack/react-query';
import { findByAccessor } from '../requests/header';

const KEY = 'header';

const headerQuery = {
  useFindByAccessor: (data = {}) => useQuery({
    queryKey: [KEY],
    queryFn: () => findByAccessor({ accessor: data }),
  }),
};

export default headerQuery;
