import prepareTranslate from '@/helpers/dictionary';
import { ACCORD_TYPE } from '@/helpers/enums';
import {
  Button,
  Center,
  Grid,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import PropTypes from 'prop-types'; // Import PropTypes

function CreateAgentClientInProcess(props) {
  const {
    form,
    productsOptions,
    companiesOptions,
  } = props;

  const t = prepareTranslate();

  const clientIndex = form.getValues().clients && form.getValues().clients.length - 1;

  return (
    <>
      <Grid gutter={{ base: 'xl' }} mb='lg'>
        <Grid.Col span={{ base: 4 }}>
          <Select
            label={t('components.createAgentClientInProcess.company')}
            data={companiesOptions}
            {...form.getInputProps(`clients.${clientIndex}.companyId`)}
          />
          <TextInput
            autoComplete='off'
            maxLength={25}
            label={t('components.createAgentClientInProcess.hp')}
            {...form.getInputProps(`clients.${clientIndex}.hp`)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 4 }}>
          <Select
            withAsterisk
            label={t('components.createAgentClientInProcess.branch')}
            data={productsOptions}
            key={form.key(`clients.${clientIndex}.branchId`)}
            {...form.getInputProps(`clients.${clientIndex}.branchId`)}
          />
          <Select
            withAsterisk
            label={t('components.createAgentClientInProcess.accordType')}
            defaultValue={ACCORD_TYPE[1].value}
            data={ACCORD_TYPE}
            {...form.getInputProps(`clients.${clientIndex}.accordType`)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 4 }}>
          <TextInput
            autoComplete='off'
            label={t('components.createAgentClientInProcess.number')}
            maxLength={7}
            withAsterisk
            {...form.getInputProps(`clients.${clientIndex}.clientNumber`)}
          />
          <NumberInput
            autoComplete='off'
            label={t('components.createAgentClientInProcess.commission')}
            maxLength={7}
            {...form.getInputProps(`clients.${clientIndex}.agentCommission`)}
          />
        </Grid.Col>
      </Grid>
      <Center>
        <Button
          type='submit'
        >
          {t('components.createAgentAgreementInProcess.submit')}
        </Button>
      </Center>
    </>
  );
}

CreateAgentClientInProcess.propTypes = {
  form: PropTypes.shape({
    insertListItem: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
    key: PropTypes.func.isRequired,
    getInputProps: PropTypes.func.isRequired,
  }).isRequired,
  productsOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  companiesOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  agentData: PropTypes.object, // Or a more specific shape if known
  currentIdentifier: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    identifierNumber: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CreateAgentClientInProcess;
