import PageTemplate from '@/components/PageTemplate';
import Section from '@/components/Section';
import prepareTranslate from '@/helpers/dictionary';
import { ROLE_LIST, USER_STATUS } from '@/helpers/enums';
import { isEmail, isPassword, isPhone } from '@/helpers/is';
import { routeWithParams } from '@/helpers/route';
import routes from '@/routes';
import {
  Button,
  Grid,
  Group,
  PasswordInput,
  Progress,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import userMutations from '@/api/mutitations/user';
import { getStrength } from '../ResetPassword/funcs';

const UserCreate = () => {
  const t = prepareTranslate();
  const navigate = useNavigate();
  const [loading, { open, close }] = useDisclosure(false);
  const { useCreateUser } = userMutations;
  const { mutateAsync } = useCreateUser();

  // eslint-disable-next-line consistent-return
  const handleUserCreateClick = async (values) => {
    try {
      open();
      // eslint-disable-next-line no-unused-vars
      const { confirmPassword, isArchived, ...userValues } = values;
      const newUser = await mutateAsync(userValues);

      if (!newUser) {
        notifications.show({
          title: t('pages.usercreate.notification.errortitle'),
          message: t('pages.usercreate.notification.error'),
        });
        close();
        return null;
      }

      notifications.show({
        title: t('pages.usercreate.notification.title'),
        message: t('pages.usercreate.notification.message'),
      });

      navigate(routeWithParams(routes.userView.path, { id: newUser }));

      close();
    } catch (error) {
      close();
    }
  };

  const handleUserCancelClick = () => {
    navigate(routes.users.path);
  };

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      userName: '',
      password: '',
      confirmPassword: '',
      role: 'administrator',
      isArchived: 'active',
      email: '',
      phone: '',
    },
    validate: {
      firstName: (value) => (value ? null : t('pages.usercreate.validations.firstname')),
      lastName: (value) => (value ? null : t('pages.usercreate.validations.lastname')),
      userName: (value) => (value ? null : t('pages.usercreate.validations.username')),
      password: (value) => (isPassword(value) ? null : t('pages.usercreate.validations.password.required')),
      confirmPassword: (value, values) => (value !== values.password ? t('pages.usercreate.validations.password.confirm') : null),
      role: (value) => (value ? null : t('pages.usercreate.validations.role')),
      isArchived: (value) => (value ? null : t('pages.usercreate.validations.status')),
      email: (value) => (isEmail(value) ? null : t('pages.usercreate.validations.email')),
      phone: (value) => (isPhone(value) ? null : t('pages.usercreate.validations.phone')),
    },
  });

  const strength = getStrength(form.values.password);

  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: '0ms' } }}
        // eslint-disable-next-line max-len, no-nested-ternary
        value={form.values.password > 0 && index === 0 ? 100 : strength >= ((index + 1) / 4) * 100 ? 100 : 0}
        // eslint-disable-next-line no-nested-ternary
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        key={index}
        size="4"
      />
    ));

  const actions = [
    <Button key="primary" loading={loading} leftSection={<IconCheck />} onClick={form.onSubmit(handleUserCreateClick)}>{t('global.save')}</Button>,
    <Button key="cancel" variant="outline" onClick={handleUserCancelClick}>{t('global.cancel')}</Button>,
  ];

  return (
      <PageTemplate
        actions={actions}
        actionsTitle={t('pages.usercreate.side.actions')}
        title={t('pages.usercreate.title')}
      >
        <form>
          <PageTemplate.Panel>
            <Section title={t('pages.usercreate.headers.private')}>
              <Grid gutter="lg">
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.usercreate.form.firstname')}
                    required
                    key={form.key('firstName')}
                    {...form.getInputProps('firstName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.usercreate.form.lastname')}
                    required
                    key={form.key('lastName')}
                    {...form.getInputProps('lastName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.usercreate.form.username')}
                    required
                    key={form.key('userName')}
                    {...form.getInputProps('userName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <Select
                    label={t('pages.usercreate.form.role')}
                    data={ROLE_LIST}
                    required
                    key={form.key('role')}
                    {...form.getInputProps('role')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <Select
                    label={t('pages.usercreate.form.status')}
                    data={USER_STATUS}
                    required
                    key={form.key('isArchived')}
                    {...form.getInputProps('isArchived')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.usercreate.headers.password')}>
              <Grid gutter="lg" >
                <Grid.Col span={{ base: 6 }}>
                    <PasswordInput
                      autoComplete="off"
                      label={t('pages.usercreate.form.password')}
                      required={true}
                      key={form.key('password')}
                      {...form.getInputProps('password')}
                    />
                  <Group gap={5} grow mt="xs" mb="md">
                    {bars}
                  </Group>
                </Grid.Col>

                <Grid.Col span={{ base: 6 }}>
                  <PasswordInput
                    autoComplete="off"
                    label={t('pages.usercreate.form.confirmpassword')}
                    required={true}
                    key={form.key('confirmPassword')}
                    {...form.getInputProps('confirmPassword')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.usercreate.headers.contact')}>
              <Grid gutter="lg">
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.usercreate.form.email')}
                    required
                    key={form.key('email')}
                    {...form.getInputProps('email')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.usercreate.form.phone')}
                    required
                    key={form.key('phone')}
                    {...form.getInputProps('phone')}
                  />
                </Grid.Col>
              </Grid>
            </Section>
          </PageTemplate.Panel>
        </form>
      </PageTemplate>
  );
};

export default UserCreate;
