export default {
  title: 'שלום {1}',
  subtitle: 'התחברות אחרונה: {1}',
  link: {
    example: {
      title: 'עמוד דוגמא',
    },
    error: {
      title: 'עמוד שגיאה',
    },
  },
  firstChart: {
    title: 'גודל תיק נכון לתאריך {1}/{2}',
  },
  secondChart: {
    title: ' סך הכנסות נכון לתאריך {1}/{2}',
  },
  button: {
    action: {
      start: 'לחץ כאן',
      text: 'מספר לחיצות {1}',
    },
  },
};
