export default {
  subtitle: 'בחירה של דוח והמאפיינים שלו',
  action: 'בחר',
  form: {
    report: 'נתון עבור דוח',
    category: 'נתון בסיס להשוואה (ציר ה - x)',
    company: 'חברה מנהלת',
    product: 'מוצר',
    daterange: 'תקופה',
    agent: 'סוכן',
  },
  headers: {
    report: 'בחירת דוח',
    properties: 'מאפייני הדוח',
    agent: 'סוכן',
  },
  side: {
    tabs: 'מידע נוסף',
    favorites: 'מועדפים',
  },
  info: {
    title: 'תכולת הדוח - {1}',
  },
  labels: {
    agents: 'כל הסוכנים בסוכנות',
    products: 'כל המוצרים',
    companies: 'כל החברות',
  },
  placeHolders: {
    products: 'ניתן לבחור עד שלושה מוצרים',
    company: 'ניתן לבחור חברה אחת',
  },
  noAgents: 'לא נמצאו סוכנים עבור המסנן',
  notification: {
    error: {
      title: 'שגיאה בחיפוש סוכנים',
      message: 'אירעה שגיאה בחיפוש סוכנים. אנא רעננו את הדף ונסו שנית',
    },
  },
};
