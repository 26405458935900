import prepareTranslate from '@/helpers/dictionary';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { createYearsArray } from '@/helpers/dates';
import { IconCircleCheckFilled, IconCircleFilled, IconExclamationMark } from '@tabler/icons-react';
import { CUSTOM_MIME_TYPES, FORM_ERRORS, MONTHS } from '@/helpers/enums';
import clsx from 'clsx';
import companyQuery from '@/api/queries/company';
import importRuleQuery from '@/api/queries/importRule';
import docsMutation from '@/api/mutitations/docs';
import { notifications } from '@mantine/notifications';
import {
  Grid,
  Select,
  Button,
  Text,
  Flex,
  Stack,
  Alert,
  ActionIcon,
  LoadingOverlay,
  ScrollArea,
  ThemeIcon,
} from '@mantine/core';
import styles from './style.module.css';
import CustomDropZone from '../CustomDropZone';

const Import = () => {
  const t = prepareTranslate();

  const form = useForm({
    initialValues: {
      companyId: null,
      importRuleId: null,
      month: null,
      year: null,
      files: null,
    },
    validate: {
      companyId: (value) => (value === null ? FORM_ERRORS.required : null),
      importRuleId: (value) => (value === null ? FORM_ERRORS.required : null),
      month: (value) => (value === null ? FORM_ERRORS.required : null),
      year: (value) => (value === null ? FORM_ERRORS.required : null),
      files: () => validateFiles(),
    },
  });

  const [files, setFiles] = useState([]);
  const [years] = useState(createYearsArray());
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [contentErrors, setContentErrors] = useState({ enable: false, data: [] });
  const [charecharisticsErrors, setCharecharisticsErrors] = useState({ enable: false, data: [] });
  const [toggleError, setToggleError] = useState(true);

  const { data: companies, isPending: companyPending } = companyQuery.useGetAll();
  const {
    mutateAsync: importMutate,
    isPending: importLoading,
  } = docsMutation.useImport();
  const {
    data: importRuleRes,
    isFetching: importRulePending,
  } = importRuleQuery.useGetByCompanyId({ companyId: form.getValues().companyId });

  const validateFiles = () => {
    if (filesToUpload.length !== 0 && filesToUpload.every((file) => file.uploaded === true)) {
      return null;
    }

    return FORM_ERRORS.required;
  };

  const handleSubmit = async (data) => {
    const formData = new FormData();
    const requestData = { importRuleId: data.importRuleId, year: data.year, month: data.month };

    files.forEach((file) => { formData.append('files', file.value); });
    formData.append('requestData', JSON.stringify(requestData));
    await importMutate(formData)
      .then(() => {
        notifications.show({
          title: t('components.import.title'),
          message: t('components.import.notifications.success'),
        });
        form.setValues({
          importRuleId: null,
          files: null,
        });
        setFilesToUpload([]);
      })
      .catch((error) => {
        let errorMessage;

        setFiles([]);
        setFilesToUpload((prev) => prev.map((file) => ({ ...file, uploaded: false })));

        if (Array.isArray(error.data)) {
          if (error.data[0]?.code) {
            setCharecharisticsErrors({ enable: true, data: error.data });
          } else {
            setContentErrors({ enable: true, data: error.data });
          }
          errorMessage = t('components.import.notifications.validationError');
        } else {
          errorMessage = t('components.import.notifications.internalError');
        }

        notifications.show({
          title: t('components.import.title'),
          message: errorMessage,
          color: 'red',
        });
      });
  };

  const handleCompanySelect = async () => {
    setFilesToUpload([]);
    form.setValues({ month: null, year: null });
    form.setFieldValue('importRuleId', null);
  };

  const handleImportRuleSelect = async (value) => {
    setFiles([]);
    const importRulefiles = importRuleRes.find((importRule) => importRule._id === value).files;
    setFilesToUpload(importRulefiles.map((file) => ({
      title: file.title,
      uploaded: false,
      uploadedFileName: null,
    })));
  };

  const handleAccept = (value) => {
    setContentErrors({ enable: false, data: [] });
    setCharecharisticsErrors({ enable: false, data: [] });
    const fileData = value[0];
    const fileName = fileData.name;
    const fileExists = files.find((file) => file.title === fileName);
    const validFile = filesToUpload.findIndex((file) => fileName.includes(file.title));

    if (validFile !== -1) {
      if (!fileExists) {
        setFiles([{ title: fileName, value: fileData }, ...files]);
        const filesToUplaoadCopy = [...filesToUpload];
        filesToUplaoadCopy[validFile].uploaded = true;
        filesToUplaoadCopy[validFile].uploadedFileName = fileName;
        setFilesToUpload(filesToUplaoadCopy);
      }
    } else {
      setCharecharisticsErrors({
        enable: true,
        data: [{ message: FORM_ERRORS.fileTitleInvalid }],
      });
      setFiles([]);
      setFilesToUpload((prev) => prev.map((fileToUpload) => ({ ...fileToUpload, uploaded: false, uploadedFileName: '' })));
    }
  };

  const handleAlertClose = () => {
    setToggleError((prev) => !prev);
  };

  const disabledInput = !form.isValid('companyId');
  const disabledFiles = !form.isValid('importRuleId');

  const filesToUploadClass = clsx(
    styles.files_to_upload_wrapper,
    filesToUpload.length === 0 && styles.display_none,
  );
  const disabledSubmitButton = !form.isValid();

  const companiesOptions = companies ? (
    companies.map((company) => ({
      label: company.hebrew,
      value: company._id,
    }))) : (
    []
  );

  const importRuleOptions = importRuleRes?.map((importRule) => ({
    label: importRule.ruleName,
    value: importRule._id,
  }));

  const drawerLoading = companyPending || importRulePending;
  const enabledErrors = charecharisticsErrors.enable || contentErrors.enable;

  return (
    <div className={styles.wrapper}>
      <LoadingOverlay visible={drawerLoading}/>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))} encType='multipart/form-data'>
        <header className={styles.head}>
          <Text c="dimmed" className={styles.subtitle}>{t('components.import.subtitle')}</Text>
        </header>

        <div className={styles.content}>
          <Grid gutter="lg">
            <Grid.Col span={{ base: 12 }}>
              <Select
                data-autofocus
                label={t('components.import.form.company')}
                data={companiesOptions}
                clearable
                key={form.key('companyId')}
                onOptionSubmit={(option) => handleCompanySelect(option)}
                {...form.getInputProps('companyId')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12 }}>
              <Select
                label={t('components.import.form.files')}
                data={importRuleOptions}
                clearable
                disabled={disabledInput}
                onOptionSubmit={(option) => handleImportRuleSelect(option)}
                key={form.key('importRuleId')}
                {...form.getInputProps('importRuleId')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6 }}>
              <Select
                label={t('components.import.form.year')}
                data={years}
                clearable
                disabled={disabledInput}
                key={form.key('year')}
                {...form.getInputProps('year')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6 }}>
              <Select
                label={t('components.import.form.month')}
                data={MONTHS}
                clearable
                disabled={disabledInput}
                key={form.key('month')}
                {...form.getInputProps('month')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12 }} mt="md">
              <CustomDropZone
                disabled={disabledFiles}
                handleAccept={handleAccept}
                outsideAccept={
                  [
                    CUSTOM_MIME_TYPES.xlsx,
                    CUSTOM_MIME_TYPES.xls,
                    CUSTOM_MIME_TYPES.xlsb,
                  ]
                }
              />
              <Stack className={filesToUploadClass}>
                {
                  filesToUpload.map((file, index) => {
                    const { uploaded, title, uploadedFileName } = file;
                    const color = uploaded ? 'green' : 'grey';
                    const text = uploadedFileName ? `${title} (${uploadedFileName})` : title;
                    const icon = uploaded ? (
                      <ThemeIcon variant='white' className={styles.circle_icon} b>
                        <IconCircleCheckFilled color={color} />
                      </ThemeIcon>
                    ) : (
                      <ThemeIcon variant='white' className={styles.circle_icon}>
                        <IconCircleFilled color={color} />
                      </ThemeIcon>
                    );

                    return (
                      <Flex
                        key={`fileToUpload${index}`}
                        align='center'
                        gap='sm'
                        c={color}
                      >
                        {icon}
                        <Text className={styles.files_to_upload_text} size='md'>
                          {text}
                        </Text>
                      </Flex>
                    );
                  })
                }
              </Stack>
            </Grid.Col>
            {
              enabledErrors && (
                <Grid.Col>
                  {
                    toggleError ? (
                      <ScrollArea h={300}>
                        <Alert className={styles.alert} color='red' withCloseButton onClose={() => handleAlertClose()}>
                          {
                            charecharisticsErrors.enable ? (
                              charecharisticsErrors.data.map((error, errorIndex) => {
                                const errorMessage = error.message;

                                return (
                                  <Text mt={errorIndex === 0 ? 0 : 'sm'} key={`fileError${errorIndex}`}>{errorMessage}</Text>
                                );
                              })
                            ) : (
                              contentErrors.data.map((file, fileIndex) => {
                                const fileTitle = file.title;
                                const fileSheets = file.sheets;

                                return (
                                  fileSheets.map((sheet, sheetIndex) => {
                                    const sheetTitle = sheet.title;
                                    const sheetErrors = sheet.errors;

                                    return (
                                      <div className={styles.file_errors_file} key={`fileErrorsTitle${fileIndex}${sheetIndex}`}>
                                          <div className={styles.file_errors_titles}>
                                            <Text>
                                              קובץ: {fileTitle}
                                            </Text>
                                            <Text>גליון: {sheetTitle}</Text>
                                          </div>
                                          <div className={styles.file_errors_body}>
                                            {
                                              sheetErrors.map((error, sheetErrorIndex) => {
                                                // eslint-disable-next-line max-len
                                                const { line: errorLine, message: errorMessage } = error;
                                                const erorrText = errorLine ? `שורה ${errorLine}: ${errorMessage}` : errorMessage;

                                                return (
                                                  <Text className={styles.file_error_description} key={`sheetErrors${fileIndex}${sheetIndex}${sheetErrorIndex}`}>{erorrText}</Text>
                                                );
                                              })
                                            }
                                          </div>
                                      </div>
                                    );
                                  })
                                );
                              })
                            )
                          }
                        </Alert>
                      </ScrollArea>
                    ) : (
                      <ActionIcon color='red'>
                        <IconExclamationMark onClick={() => handleAlertClose()} color='white'/>
                      </ActionIcon>
                    )
                  }
                </Grid.Col>
              )
            }
            <Grid.Col span={{ base: 12 }}>
              <Button disabled={disabledSubmitButton} type="submit" loading={importLoading}>{t('components.import.form.action')}</Button>
            </Grid.Col>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default Import;
