export default {
  notification: {
    title: 'נוצרו הערות בעיבוד הנתונים',
    message: 'לא זוהו סוכנים בקובץ {1}',
    click: 'יש ללחוץ כאן על מנת להשלים את המידע',
  },
  popUp: {
    clients: 'תיק',
    agreements: 'הסכם',
    message: '{2} מספר {1} לא קיים במערכת',
    createAgent: 'יצירת סוכן',
    createAgreement: 'יצירת הסכם',
    createClient: 'יצירת תיק',
    skip: 'דלג',
    skipDisclamer: 'דילוג משמעו כי מידע הקשור למספר לא יקלט במערכת',
    company: 'חברה מנהלת:',
    product: 'מוצר:',
    year: 'שנה:',
    month: 'חודש:',
  },
  errors: {
    agreement: 'אירעה שגיאה ביצירת ההסכם. אנא נסו שנית',
    client: 'אירעה שגיאה ביצירת התיק. אנא נסו שנית',
  },
  title: 'כל ההערות עודכנו',
};
