import Table from '@/components/Table';
import { notifications } from '@mantine/notifications';
import prepareTranslate from '@/helpers/dictionary';
import { useDisclosure } from '@mantine/hooks';
import { Container } from '@mantine/core';
import {
  IconDownload,
  IconX,
} from '@tabler/icons-react';
import userMutations from '@/api/mutitations/upload';
import { truncateString } from '@/helpers/string';
import { DateFormats } from '@/helpers/enums';
import PageTemplate from '@/components/PageTemplate';

const Uploads = () => {
  const t = prepareTranslate();

  // const { data: files } = uploadQuery.useGetFiles();
  const { useAllUploads, useDownload, useDeleteUpload } = userMutations;
  const getAllUploads = useAllUploads();
  const downloadById = useDownload();
  const deleteUpload = useDeleteUpload();
  // eslint-disable-next-line no-unused-vars
  const [loading, { open, close }] = useDisclosure(false);

  // eslint-disable-next-line consistent-return
  const getData = async (start, end, query) => {
    try {
      const response = await getAllUploads.mutateAsync({ start, end, query });
      const formatedFiles = response.data.map((file) => (
        {
          _id: file._id,
          fileName: truncateString(file.fileName),
          fileType: file.fileType || '',
          createdAt: file.createdAt,
          uploadedBy: `${file.authorFirstName || ''} ${file.authorLastName || ''}`,
          uploadedFor: `${file.entityId?.personalDetails.firstName || ''} ${file.entityId?.personalDetails.lastName || ''}`,
        }
      ));
      response.data = formatedFiles;
      return response;
    } catch (error) {
      notifications.show({
        title: t('pages.uploads.title'),
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching uploads:', error);
    }
  };

  const handleClickOnDelete = async (items = []) => {
    await deleteUpload.mutateAsync({ data: items });

    // TODO reload page in better way
    // refreshRows(items, 'delete');
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const handleClickOnDownload = async (fileId) => {
    try {
      open();
      notifications.show({
        title: t('pages.agentEdit.notification.download.title'),
        message: t('pages.agentEdit.notification.download.message'),
      });
      const response = await downloadById.mutateAsync({ fileId });
      const { file, contentType, fileName } = response;

      // Decode base64 string to binary
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;

      // Trigger the download
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      close();
    } catch (error) {
      open();
      notifications.show({
        title: t('pages.agentEdit.notification.downloadFaild.title'),
        message: t('pages.agentEdit.notification.downloadFaild.message'),
      });
      close();
    }
  };

  const mockColumns = [
    {
      header: 'שם קובץ',
      accessor: 'fileName',
      filter: {
        type: 'string',
      },
      sort: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'סוג',
      accessor: 'fileType',
      filter: {
        type: 'string',
      },
      sort: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'שיוך לסוכן',
      accessor: 'uploadedFor',
      filter: {
        type: 'string',
      },
      sort: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'תאריך העלאה',
      accessor: 'createdAt',
      transforms: ['date'],
      filter: {
        type: 'date',
      },
      sort: {
        type: 'date',
        format: DateFormats.fullDate,
      },
      isVisible: true,
    },
    {
      header: 'הועלה על ידי',
      accessor: 'uploadedBy',
      filter: {
        type: 'string',
      },
      sort: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'פעולות',
      actions: [
        {
          title: 'הורדה',
          action: handleClickOnDownload,
          icon: <IconDownload />,
          showLabel: true,
          isPrimary: true,
        },
      ],
    },
  ];

  const mockSelectionActions = [
    {
      title: 'מחיקה',
      icon: <IconX stroke='1' />,
      onClick: handleClickOnDelete,
    },
  ];

  return (
    <>
      <PageTemplate
        title={t('pages.uploads.title')}
      >

      <Container fluid>
        <Table
          columns={mockColumns}
          getData={getData}
          selectionActions={mockSelectionActions}
          isColumnsActive={false}
          isExportActive={false}
        />
        </Container>
        </PageTemplate>
    </>
  );
};

export default Uploads;
