import PropTypes from 'prop-types';
import clsx from 'clsx';
import { UnstyledButton } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import styles from './style.module.css';

const NavbarLink = (
  {
    icon: Icon, label, to, onClick, expanded,
  },
) => {
  const navLinkClasses = ({ isActive }) => clsx(styles.link, isActive && styles.active);
  const handleButtonClick = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <>
      {onClick ? (
        <>
          <div className={styles.container}>
            <UnstyledButton className={styles.link} onClick={handleButtonClick}>
          <Icon />
        </UnstyledButton>
       {expanded && <span className={styles.label}>{label}</span>}
</div></>
      ) : (
          <div className={styles.container}>
        <NavLink to={to} className={navLinkClasses}>
          <Icon />
        </NavLink>
         {expanded && <span className={styles.label}>{label}</span>}
</div>
      )}
    </>
  );
};

NavbarLink.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  expanded: PropTypes.bool,
};

export default NavbarLink;
