import PropTypes from 'prop-types';
import prepareTranslate from '@/helpers/dictionary';
import productQuery from '@/api/queries/product';
import companyQuery from '@/api/queries/company';
import { MonthPickerInput } from '@mantine/dates';
import { ACCORD_TYPE } from '@/helpers/enums';
import { useState } from 'react';
import {
  Button,
  Grid,
  MultiSelect,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import PageTemplate from '../PageTemplate';
import CustomCollapse from '../CustomCollapse';

const CreateContracts = (props) => {
  const {
    form,
  } = props;

  const t = prepareTranslate();

  const [openedCollapse, setOpenedCollapse] = useState(-1);

  const { data: products } = productQuery.useGetAll();
  const { data: companies } = companyQuery.useGetAll();

  const productsOptions = products?.map((product) => ({
    label: product.hebrew,
    value: product._id,
  }));

  const companiesOptions = companies?.map((company) => ({
    label: company.hebrew,
    value: company._id,
  }));

  const handleCollapseManager = (index) => {
    let setNumber = index;

    if (openedCollapse === index) {
      setNumber = -1;
    }

    setOpenedCollapse(setNumber);
  };

  const handleDeleteCollapseManager = (index) => {
    form.removeListItem('agreements', index);
  };

  const handleAddAgreement = () => {
    form.insertListItem('agreements', {
      accordType: ACCORD_TYPE[0].value,
      agentNumber: '',
    });
    setOpenedCollapse(form.getValues().agreements.length - 1);
  };

  return (
      <PageTemplate.Panel id='contracts'>
        {form.getValues().agreements.map((_, index) => {
          const agreementAccordType = form.getValues().agreements[index].accordType;

          return (
            <CustomCollapse
              title={`הסכם מספר ${index + 1}`}
              onDelete={() => handleDeleteCollapseManager(index)}
              onClick={() => handleCollapseManager(index)}
              open={index === openedCollapse}
              key={index + 1}
            >
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    autoComplete='off'
                    label={t('components.createContracts.company')}
                    data={companiesOptions}
                    required
                    key={form.key(`agreements.${index}.companyId`)}
                    maxLength={20}
                    {...form.getInputProps(`agreements.${index}.companyId`)}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    required
                    label={t('components.createContracts.agent')}
                    key={form.key(`agreements.${index}.agentNumber`)}
                    maxLength={10}
                    {...form.getInputProps(`agreements.${index}.agentNumber`)}
                  />
                </Grid.Col>
              </Grid>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 6 }}>
                  <MonthPickerInput
                  required
                    maxDate={new Date()}
                    label={t('components.createContracts.from')}
                    key={form.key(`agreements.${index}.from`)}
                    {...form.getInputProps(`agreements.${index}.from`)}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <MonthPickerInput
                    label={t('components.createContracts.till')}
                    key={form.key(`agreements.${index}.till`)}
                    {...form.getInputProps(`agreements.${index}.till`)}
                  />
                </Grid.Col>
              </Grid>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <MultiSelect
                    required
                    label={t('components.createContracts.branch')}
                    data={productsOptions}
                    key={form.key(`agreements.${index}.branchId`)}
                    {...form.getInputProps(`agreements.${index}.branchId`)}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    required
                    label={t('components.createContracts.accordType')}
                    data={ACCORD_TYPE}
                    key={form.key(`agreements.${index}.accordType`)}
                    {...form.getInputProps(`agreements.${index}.accordType`)}
                  />
                </Grid.Col>
              </Grid>
              {
                agreementAccordType === 'independent' && (
                  <Grid gutter='lg'>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                      <NumberInput
                        autoComplete='off'
                        label={t('components.createContracts.commision.agentFeeMonthlyAccumulation')}
                        key={form.key(`agreements.${index}.agentFeeMonthlyAccumulation`)}
                        maxLength={5}
                        min={0}
                        {...form.getInputProps(`agreements.${index}.agentFeeMonthlyAccumulation`)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                      <NumberInput
                        autoComplete='off'
                        label={t('components.createContracts.commision.agentFeeMonthlyDeposit')}
                        key={form.key(`agreements.${index}.agentFeeMonthlyDeposit`)}
                        maxLength={5}
                        min={0}
                        {...form.getInputProps(`agreements.${index}.agentFeeMonthlyDeposit`)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                      <NumberInput
                        autoComplete='off'
                        label={t('components.createContracts.commision.agentFeeAnnualDeposit')}
                        key={form.key(`agreements.${index}.agentFeeAnnualDeposit`)}
                        maxLength={5}
                        min={0}
                        {...form.getInputProps(`agreements.${index}.agentFeeAnnualDeposit`)}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                      <NumberInput
                        autoComplete='off'
                        label={t('components.createContracts.commision.agentFeeAnnualAccumulation')}
                        key={form.key(`agreements.${index}.agentFeeAnnualAccumulation`)}
                        maxLength={5}
                        min={0}
                        {...form.getInputProps(`agreements.${index}.agentFeeAnnualAccumulation`)}
                      />
                    </Grid.Col>
                  </Grid>
                )
              }
              {
                agreementAccordType !== 'independent' && (
                  <Grid gutter='lg'>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                      <NumberInput
                        autoComplete='off'
                        label={t('components.createContracts.commision.employeeAgentCommission')}
                        key={form.key(`agreements.${index}.employeeAgentCommission`)}
                        maxLength={5}
                        min={0}
                        {...form.getInputProps(`agreements.${index}.employeeAgentCommission`)}
                      />
                    </Grid.Col>
                  </Grid>
                )
              }
               <Grid gutter='lg'>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                      <TextInput
                        label={t('components.createContracts.accordName')}
                        key={form.key(`agreements.${index}.accordName`)}
                        maxLength={200}
                        min={0}
                        {...form.getInputProps(`agreements.${index}.accordName`)}
                      />
                    </Grid.Col>
                  </Grid>
            </CustomCollapse>
          );
        })}
        <Button
          mt={15}
          mb={15}
          onClick={handleAddAgreement}
        >
          {t('components.createContracts.addAgreement')}
        </Button>
    </PageTemplate.Panel>
  );
};

CreateContracts.propTypes = {
  form: PropTypes.object,
};

export default CreateContracts;
