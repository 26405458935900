import prepareTranslate from '@/helpers/dictionary';
import {
  Button, Card, Center, Text,
} from '@mantine/core';
import PropTypes from 'prop-types';
import { noDataActions } from '@/helpers/enums';
import styles from './style.module.css';

const NoData = (props) => {
  const { noDataAction, title } = props;
  const { action, label } = noDataActions[noDataAction] || {};
  const t = prepareTranslate();

  return (
    <Card shadow="sm" radius="md" padding="xl" mt="l" className={styles.empty} >
      <Text fw="bold">{title}</Text>
      <Text size="xs" c="dimmed">{t('components.chart.noRows.subtitle')}</Text>
      <Center>
        <Button onClick={action} p={1} m={20} w={200} >{label}</Button>
      </Center>
    </Card>
  );
};

export default NoData;

NoData.propTypes = {
  noDataAction: PropTypes.string,
  title: PropTypes.string,
};
