import { Outlet } from 'react-router-dom';
import { Notifications } from '@mantine/notifications';
import useAccessToken from '@/hooks/useAccessToken';
import { useEventSource, useEventSourceListener } from '@react-nano/use-event-source';
import { useState } from 'react';
import SSEHandler from '@/components/SSEHandler';
import vars from '@/config/vars';
import styles from './style.module.css';

const DefaultLayout = () => {
  const [event, setEvent] = useState();
  const { isLoading } = useAccessToken();

  const [eventSource] = useEventSource(vars.request.sseUrl, true);

  useEventSourceListener(
    eventSource,
    ['error', 'message'],
    async (e) => setEvent(e),
    [],
  );

  if (isLoading) {
    return '';
  }

  return (
  <div className={styles.layout}>
      <Notifications position='bottom-left' className={styles.notifications} zIndex={250} />
    <main>
      <Outlet />
      <SSEHandler event={event} />
    </main>
  </div>
  );
};

export default DefaultLayout;
