import { useDisclosure } from '@mantine/hooks';
import prepareTranslate from '@/helpers/dictionary';
import { useForm } from '@mantine/form';
import {
  Grid,
  MultiSelect,
  NumberInput,
  Select,
} from '@mantine/core';
import AgencyCommissionsSwitch from '@/components/AgencyCommissionsSwitch';
import companyQuery from '@/api/queries/company';
import productQuery from '@/api/queries/product';
import validations from '@/helpers/validation';
import Template from './template';

function AgentCommissionsCreate() {
  const t = prepareTranslate();

  const { data: companiesData } = companyQuery.useGetAll();
  const { data: productsData } = productQuery.useGetAll();
  const [loading, { open, close }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      agencyFeeMonthlyDeposit: 0,
      agencyFeeMonthlyAccumulation: 0,
      agencyFeeAnnualDeposit: 0,
      agencyFeeAnnualAccumulation: 0,
      areFeesIncludingVAT: false,
    },
    validate: {
      company: (value) => validations.required(value),
      product: (value) => validations.required(value),
    },
  });

  const companiesOptions = companiesData ? (
    companiesData.map((company) => ({
      label: company.hebrew,
      value: company._id,
    }))) : (
    []
  );

  const productsOptions = productsData?.map((product) => ({
    label: product.hebrew,
    value: product._id,
  }));

  return (
    <>
      <Template
        loading={loading}
        open={open}
        close={close}
        form={form}
      >
        <Grid gutter='lg'>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('pages.agencyCommissionsCreate.form.company')}
              data={companiesOptions}
              required
              key={form.key('company')}
              {...form.getInputProps('company')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <MultiSelect
              label={t('pages.agencyCommissionsCreate.form.product')}
              data={productsOptions}
              required
              key={form.key('product')}
              {...form.getInputProps('product')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <NumberInput
              label={t('pages.agencyCommissionsCreate.form.agencyFeeMonthlyDeposit')}
              key={form.key('agencyFeeMonthlyDeposit')}
              maxLength={5}
              min={0}
              defaultValue={0}
              {...form.getInputProps('agencyFeeMonthlyDeposit')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <NumberInput
              label={t('pages.agencyCommissionsCreate.form.agencyFeeMonthlyAccumulation')}
              key={form.key('agencyFeeMonthlyAccumulation')}
              maxLength={5}
              min={0}
              defaultValue={0}
              {...form.getInputProps('agencyFeeMonthlyAccumulation')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <NumberInput
              label={t('pages.agencyCommissionsCreate.form.agencyFeeAnnualDeposit')}
              key={form.key('agencyFeeAnnualDeposit')}
              maxLength={5}
              min={0}
              defaultValue={0}
              {...form.getInputProps('agencyFeeAnnualDeposit')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <NumberInput
              label={t('pages.agencyCommissionsCreate.form.agencyFeeAnnualAccumulation')}
              key={form.key('agencyFeeAnnualAccumulation')}
              maxLength={5}
              min={0}
              defaultValue={0}
              {...form.getInputProps('agencyFeeAnnualAccumulation')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <AgencyCommissionsSwitch form={form} />
          </Grid.Col>
        </Grid>
      </Template>
    </>
  );
}

export default AgentCommissionsCreate;
