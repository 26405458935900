const createYearsArray = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2000; year <= currentYear + 3; year += 1) {
    years.push(year.toString().padStart(4, '0'));
  }
  return years;
};

const createArraysFromRange = (date1, date2) => {
  let firstDate = date1;
  let secondDate = date2 || date1;

  if (!(firstDate instanceof Date) || !(secondDate instanceof Date)) {
    throw new Error('Invalid input: Both arguments must be Date objects');
  }

  // Ensure start date is before end date
  if (firstDate > secondDate) {
    [firstDate, secondDate] = [secondDate, firstDate];
  }

  const yearArray = [];
  const monthArray = [];

  const currentDate = new Date(firstDate.getTime());
  currentDate.setDate(1); // Set to first day of the month

  while (currentDate <= secondDate) {
    if (!yearArray.includes(currentDate.getFullYear())) {
      yearArray.push(currentDate.getFullYear());
    }
    monthArray.push(currentDate.getMonth() + 1); // Months are 0-indexed

    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return [yearArray, monthArray];
};

const createRangeFromArrays = (year, month) => {
  if (!Array.isArray(month) || !Array.isArray(year)) {
    throw new Error('Invalid input. Please provide two arrays');
  }

  const firstYear = year[0];
  const lastYear = year[year.length - 1];

  // Get the first date (first month, first year)
  const firstDate = new Date(firstYear, month[0] - 1, 1);

  // Get the last date (last month, last year)
  const lastDate = new Date(lastYear, month[month.length - 1] - 1, 1);

  return [firstDate, lastDate];
};

export {
  createYearsArray,
  createArraysFromRange,
  createRangeFromArrays,
};
