export default {
  noRows: {
    title: 'לא נמצאו פריטים מתאימים עבור הגרף',
    subtitle: 'נסה לשנות את המסננים ולבצע חיפוש חדש',
  },
  actionTitles: {
    addFiles: 'טעינת קבצים',
  },
  sum: 'סכום כולל {1}',
};
