import prepareTranslate from '@/helpers/dictionary';
import PageTemplate from '@/components/PageTemplate';
import routes from '@/routes';
import SectionView from '@/components/SectionView';

const Settings = () => {
  const t = prepareTranslate();

  const detailsLines = [
    {
      title: t('pages.settings.labels.version'),
      value: __VERSION__, // eslint-disable-line
    },
    {
      title: t('pages.settings.labels.environment'),
      value: import.meta.env.MODE,
    },
  ];

  return (
    <>

      <PageTemplate title={routes.settings.name}
      >
        <PageTemplate.Panel>
          <SectionView title={t('pages.settings.labels.general')} lines={detailsLines} />
        </PageTemplate.Panel>
      </PageTemplate>
    </>
  );
};

export default Settings;
