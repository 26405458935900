import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import prepareTranslate from '@/helpers/dictionary';
import { useDisclosure } from '@mantine/hooks';
import { Badge, Button, Loader } from '@mantine/core';
import Comments from '@/components/Comments';
import routes from '@/routes';
import { IconEdit } from '@tabler/icons-react';
import { routeWithParams } from '@/helpers/route';
import AgentAgreements from '@/components/ViewAgentAgreements';
import PageTemplate from '@/components/PageTemplate';
import Section from '@/components/Section';
import agentMutations from '@/api/mutitations/agent';
import uploadMutations from '@/api/mutitations/upload';
import { formatDateToString, AGENT_TYPE } from '@/helpers/string';
import ViewAgentDetails from '@/components/ViewAgentDetails';
import ViewAgentClients from '@/components/ViewAgentClients';
import ViewAgentFiles from '@/components/viewAgentFiles';

const Agentview = () => {
  const [agent, setAgent] = useState({ personalDetails: { dob: null } });

  // eslint-disable-next-line no-unused-vars
  const [loading, { open, close }] = useDisclosure(false);
  const [files, setFiles] = useState([]);

  const isActive = true;

  const t = prepareTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const { useGetOne } = agentMutations;
  const { useUploadsById } = uploadMutations;
  const getOne = useGetOne();
  const uploadsById = useUploadsById();

  const statusTitle = isActive
    ? t('global.status.active')
    : t('global.status.inactive');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const result = await getOne.mutateAsync({ id });
        const fileList = await uploadsById.mutateAsync({ id });
        setAgent(result);
        setFiles(fileList);
      } catch (error) {
        navigate(routes.agents.path);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!agent) {
    return <Loader />;
  }

  // TODO: move to agentview functions
  const menuGroups = [
    {
      links: [
        {
          id: 'details',
          title: t('pages.agentview.menu.details'),
        },
        {
          id: 'contracts',
          title: t('pages.agentview.menu.contracts', [
            agent.agreements ? `(${agent.agreements.length})` : '',
          ]),
        },
        {
          id: 'documents',
          title: t('pages.agentview.menu.documents', [
            files ? `(${files.length})` : '',
          ]),
        },
        {
          id: 'clients',
          title: t('pages.agentview.menu.clients', [
            agent.clients ? `(${agent.clients.length})` : '',
          ]),
        },
      ],
    },
  ];

  // TODO: move to agentview functions
  const tabs = [
    {
      title: t('pages.agentview.side.comments'),
      content: <Comments type='agent' id={id} showForm />,
      isDefault: true,
    },
  ];

  const handleUpdateClick = () => {
    navigate(routeWithParams(routes.agentEdit.path, { id }));
  };

  return (
    <>
      <PageTemplate
        menuGroups={menuGroups}
        tabs={tabs}
        tabsTitle={t('pages.agentview.side.tabs')}
        title={
          <>
            {t('pages.agentview.title', [agent.personalDetails.fullName || ''])}
            <Badge color='blue'>{statusTitle}</Badge>
            {agent.agentType && <Badge color='blue'>{AGENT_TYPE[agent.agentType]}</Badge>}
          </>
        }
        subtitle={t('pages.agentview.subtitle', [
          formatDateToString(agent.updatedAt),
        ])}
        headerActions={
          <Button onClick={handleUpdateClick} leftSection={<IconEdit />}>
            {t('pages.agentview.action')}
          </Button>
        }
      >

        <ViewAgentDetails agent={agent}/>

        <PageTemplate.Panel id='contracts'>
          <Section title={t('pages.agentview.headers.contracts')}>
            <AgentAgreements data={agent.agreements} id={id} />
          </Section>
        </PageTemplate.Panel>

        <ViewAgentFiles
          open={open}
          close={close}
          files={files}
        />

        <ViewAgentClients agent={agent}/>
      </PageTemplate>
    </>
  );
};

export default Agentview;
