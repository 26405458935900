import prepareTranslate from '@/helpers/dictionary';
import Table from '@/components/Table/index';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { routeWithParams } from '@/helpers/route';
import routes from '@/routes';
import {
  IconEdit,
  IconEye,
  IconX,
  IconPlus,
} from '@tabler/icons-react';
import { Button, Container } from '@mantine/core';
import agencyCommissionsMutation from '@/api/mutitations/agencyCommissions';
import { downloadFile } from '@/helpers/download';
import docsMutation from '@/api/mutitations/docs';
import productQuery from '@/api/queries/product';
import companyQuery from '@/api/queries/company';
import PageTemplate from '@/components/PageTemplate';

function AgencyCommissions() {
  const t = prepareTranslate();
  const navigate = useNavigate();
  const { mutateAsync: agencyCommissionMutate } = agencyCommissionsMutation.useGetAll();
  const { mutateAsync: agencyCommissionDeleteMutate } = agencyCommissionsMutation.useDeleteMany();
  const { data: companiesData } = companyQuery.useGetAll();
  const { data: productsData } = productQuery.useGetAll();
  const { mutateAsync: exportFileMutate } = docsMutation.useExportFile();

  const companiesOptions = companiesData?.map((company) => ({
    label: company.hebrew,
    value: company._id,
  }));

  const productsOptions = productsData?.map((product) => ({
    label: product.hebrew,
    value: product._id,
  }));

  const getData = async (start, end, query) => {
    let result = {};
    try {
      const agentCommissionResult = await agencyCommissionMutate({ start, end, query });

      const returnData = agentCommissionResult.data.map((agencyCommissions) => ({
        _id: agencyCommissions._id,
        company: agencyCommissions.company,
        product: agencyCommissions.product.map((item) => item.hebrew).join(', '),
      }));

      agentCommissionResult.data = returnData;

      result = agentCommissionResult;
    } catch (error) {
      notifications.show({
        title: t('pages.agencyCommissions.notification.errors.find.title'),
        message: t('pages.agencyCommissions.notification.errors.find.message'),
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching agency commissions:', error);
    }

    return result;
  };

  const handleClickOnView = (id) => {
    navigate(routeWithParams(routes.agencyCommissionsView.path, { id }));
  };

  const handleClickOnEdit = (id) => {
    navigate(routeWithParams(routes.agencyCommissionsEdit.path, { id }));
  };

  const handleExport = async (option, query) => {
    const requestColumns = mockColumns.filter((column) => column.header !== 'פעולות');
    const exportData = {
      query,
      fileType: option,
      service: 'agencyCommissions',
      columns: requestColumns,
      titleArray: ['personalDetails.fullName'],
    };

    const fileTitleBase = t('pages.agencyCommissions.fileTitle');
    const fileTitle = option === 'pdf' ? `${fileTitleBase}.pdf` : `${fileTitleBase}.xlsx`;

    try {
      const fileData = await exportFileMutate(exportData);
      downloadFile(fileData, fileTitle);
    } catch (error) {
      notifications.show({
        title: t('pages.agencyCommissions.notification.errors.export.title'),
        message: t('pages.agencyCommissions.notification.errors.export.message'),
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching users:', error);
    }
  };

  const handleClickOnDelete = async (items = []) => {
    try {
      await agencyCommissionDeleteMutate({ data: items });
      // TODO reload page in better way
      // refreshRows(items, 'delete');
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch {
      notifications.show({
        title: t('pages.agencyCommissions.notification.errors.delete.title'),
        message: t('pages.agencyCommissions.notification.errors.delete.message'),
      });
    }
  };

  const mockColumns = [
    {
      header: 'חברה',
      accessor: 'company.hebrew',
      filter: {
        type: 'select',
        values: companiesOptions,
      },
      isVisible: true,
    },
    {
      header: 'מוצר',
      accessor: 'product',
      filter: {
        type: 'select',
        values: productsOptions,
      },
      isVisible: true,
    },
    {
      header: 'פעולות',
      filter: {
        enable: false,
      },
      actions: [
        {
          title: 'צפייה',
          action: handleClickOnView,
          icon: <IconEye />,
          showLabel: true,
          isPrimary: true,
        },
        {
          title: 'עריכה',
          action: handleClickOnEdit,
          icon: <IconEdit />,
          showLabel: true,
          isPrimary: true,
        },
      ],
    },
  ];

  const mockSelectionActions = [
    {
      title: 'מחיקה',
      icon: <IconX stroke='1' />,
      onClick: handleClickOnDelete,
    },
  ];

  const exportOptions = ['excel'];

  const handleUpdateClick = () => {
    navigate(routes.agencyCommissionsCreate.path);
  };

  return (
    <PageTemplate title={t('pages.agencyCommissions.title')}
    headerActions={
      <Button onClick={handleUpdateClick} leftSection={<IconPlus />}>
        {t('pages.agencyCommissions.action')}
      </Button>
    }>
      <Container fluid>
        <Table
          isExportActive
          columns={mockColumns}
          onExport={handleExport}
          getData={getData}
          selectionActions={mockSelectionActions}
          isColumnsActive={false}
          exportOptions={exportOptions}
        />
      </Container>
    </PageTemplate>
  );
}

export default AgencyCommissions;
