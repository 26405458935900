import SectionView from '@/components/SectionView';
import agencyCommissionsQuery from '@/api/queries/agencyCommissions';
import { useNavigate, useParams } from 'react-router-dom';
import prepareTranslate from '@/helpers/dictionary';
import routes from '@/routes';
import { useEffect } from 'react';
import Template from './template';

function AgencyCommissionsView() {
  const t = prepareTranslate();
  const { id } = useParams();
  const navigate = useNavigate();

  // eslint-disable-next-line max-len
  const { data: agencyCommissionData, status } = agencyCommissionsQuery.useGetAgencyCommissionsById({ id });

  const productValue = agencyCommissionData?.product.map((item) => `${item.hebrew} `).join(', ');
  const areFeesIncludingVAT = agencyCommissionData?.areFeesIncludingVAT ? t('global.yes') : t('global.no');

  const detailsLines = [
    {
      title: t('pages.agencyCommissionsView.details.company'),
      value: agencyCommissionData?.company.hebrew,
    },
    {
      title: t('pages.agencyCommissionsView.details.product'),
      value: productValue,
    },
    {
      title: t('pages.agencyCommissionsView.details.agencyFeeMonthlyDeposit'),
      value: agencyCommissionData?.agencyFeeMonthlyDeposit,
    },
    {
      title: t('pages.agencyCommissionsView.details.agencyFeeMonthlyAccumulation'),
      value: agencyCommissionData?.agencyFeeMonthlyAccumulation,
    },
    {
      title: t('pages.agencyCommissionsView.details.agencyFeeAnnualAccumulation'),
      value: agencyCommissionData?.agencyFeeAnnualAccumulation,
    },
    {
      title: t('pages.agencyCommissionsView.details.agencyFeeAnnualDeposit'),
      value: agencyCommissionData?.agencyFeeAnnualDeposit,
    },
    {
      title: t('pages.agencyCommissionsView.details.areFeesIncludingVAT'),
      value: areFeesIncludingVAT,
    },
  ];

  useEffect(() => {
    if (!agencyCommissionData && status !== 'pending') {
      navigate(routes.agencyCommissions.path);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <Template>
        <SectionView
          lines={detailsLines}
        />
      </Template>
    </>
  );
}

export default AgencyCommissionsView;
