import prepareTranslate from '@/helpers/dictionary';
import {
  Anchor,
  Center,
  Container,
  Grid,
  Loader,
  Text,
} from '@mantine/core';
import PageTemplate from '@/components/PageTemplate';
import Box from '@/components/Box';
import companyQuery from '@/api/queries/company';
import authQuery from '@/api/queries/auth';
import docsMutation from '@/api/mutitations/docs';
import ChartComponent from '@/components/Chart';
import { isObjectEmpty } from '@/helpers/is';
import { formatDate, objectToQueryString } from '@/helpers/string';
import routes from '@/routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routeWithParams } from '@/helpers/route';
import dashboardQuery from '../../api/queries/dashboard';

const Main = () => {
  const t = prepareTranslate();

  const { data: companyData } = companyQuery.useGetAll();
  const { data: userData } = authQuery.useProfile();
  const navigate = useNavigate();

  const firstName = userData?.firstName;
  const lastLoginAttempt = userData?.lastLoginAttempt;
  const lastUserLogin = formatDate(lastLoginAttempt);
  const [showLink, setShowLink] = useState([{ 0: true }, { 1: true }]);
  const { data: dashboardData, isLoading } = dashboardQuery.useGetAll();
  const { mutateAsync: chartMutateAsync } = docsMutation.useExportChart();
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    const filtersData = dashboardData?.charts.map((chart) => ({
      year: chart.year,
      month: chart.month,
    }));
    setFilters(filtersData);
  }, [dashboardData]);

  const buildChartQuery = (query, ruleId, category) => {
    if (isObjectEmpty(query)) return null;
    const {
      agent = [],
      year,
      month,
      company = [],
      product = [],
    } = query;

    const seriesFormat = { name: [] };

    if (company.length) seriesFormat.name.unshift('company');

    if (!seriesFormat.name.length) seriesFormat.name.push('primaryValue');

    return {
      exportRuleId: ruleId,
      seriesFormat,
      year,
      month,
      identifiers: agent,
      query: {},
      additionalData: { companyId: company, productId: product },
      categoriesType: category,
    };
  };

  const getChartData = async (query, ruleId, category) => {
    const queryData = buildChartQuery(query, ruleId, category);
    if (!queryData) return { data: [], series: [] };
    try {
      return await chartMutateAsync(queryData);
    } catch {
      return { data: [], series: [] };
    }
  };

  const fetchChart = async (query, chartKey) => {
    const chart = dashboardData.charts[chartKey];
    const { ruleId, category } = chart;
    [filters[chartKey].year] = query.year;
    [filters[chartKey].month] = query.month;
    const chartData = await getChartData(query, ruleId, category, chartMutateAsync);

    if (chartData.data.length === 0) {
      setShowLink({ ...showLink, [chartKey]: false });
    } else {
      setShowLink({ ...showLink, [chartKey]: true });
    }
    return chartData;
  };

  const barClick = (query, company, chartKey) => {
    onBarClick(query, company, dashboardData.charts[chartKey].ruleId);
  };

  const onBarClick = (query, selectedCategory, ruleId) => {
    const company = companyData.find((c) => c.hebrew === selectedCategory.category);
    const updatedQuery = { ...query, company: [company?._id] };
    const chartQuery = buildChartQuery(updatedQuery, ruleId, 'product');

    const path = chartQuery.identifiers.length > 1
      ? routes.reportList.path
      : routes.reportsView.path;

    const queryParams = objectToQueryString({
      month: chartQuery.month,
      year: chartQuery.year,
      company: chartQuery.additionalData.companyId,
      product: chartQuery.additionalData.productId,
      category: chartQuery.categoriesType,
      agent: [],
    });
    navigate(routeWithParams(path, { id: chartQuery.exportRuleId, query: queryParams }));
  };

  const onBoxClick = (index) => {
    switch (index) {
      case 0:
        navigate(routes.agents.path);
        break;
      default:
        break;
    }
  };

  const onLinkClick = (index) => {
    const chart = dashboardData.charts[index];

    const {
      ruleId,
      category,
      additionalData,
      identifiers,
    } = chart;

    const {
      year,
      month,
    } = filters[index];

    const path = identifiers.length > 1
      ? routes.reportList.path
      : routes.reportsView.path;

    const queryParams = objectToQueryString({
      month: [month],
      year: [year],
      company: additionalData.companyId,
      product: additionalData.productId,
      category,
      agent: [],
    });

    navigate(routeWithParams(path, { id: ruleId, query: queryParams }));
  };
  return (
    <>
     <PageTemplate title={t('pages.main.title', [firstName])}
        subtitle={t('pages.main.subtitle', [lastUserLogin])}>
      <Container fluid>
        {isLoading || !filters ? <Center h={'50vh'}>
      <Loader size={50} />
       </Center>
          : <Grid gutter="xl" pt={'lg'}>
            {dashboardData.charts.map((element, index) => (
              <Grid.Col key={index} span={6}>
              <Box h={'36rem'}>
                <Text size="2rem" fw={700} c='var(--mantine-color-brand-6)' ta='center' mt="xl">{ element.title}</Text>
                <ChartComponent
                  defaultValue={element.data?.data}
                  getData={(value) => fetchChart(value, index)}
                  useDateSelectors
                  defaultFilters= {filters[index]}
                  onBarClick={(query, bar) => barClick(query, bar, index)}
                  isChartTypeActive={false}
                  noDataAction='addFiles'
                  />
                  {showLink[index] && <Center>
                    <Anchor c={'var(--mantine-color-secondary-0'} mt={'2rem'} underline="always"
                     onClick={() => {
                       onLinkClick(index);
                     }}
                 >
                   {element.subtitle}
                  </Anchor>
               </Center>
                  }
              </Box>
            </Grid.Col>
            ))}
          {dashboardData.boxes.map((element, index) => (
            <Grid.Col span={3} key={element.title}>
              <Box title={element.title} onClickHandler={() => onBoxClick(index)}>
                <Center>
                  <Text size="2rem" fw={700} mt={'md'}>
                     {element.result || 0 }
                </Text>
                </Center>
                </Box>
            </Grid.Col>))}
            <Grid.Col span={3} >
              <Box title='אי התאמות עמלה'>
                <Center>
                  <Text size="2rem" fw={700} mt={'md'}>
                     { 0 }
                </Text>
                </Center>
                </Box>
            </Grid.Col>
        </Grid>
        }
        </Container>
        </PageTemplate>
    </>
  );
};

export default Main;
