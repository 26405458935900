import clsx from 'clsx';
import { Container, Flex } from '@mantine/core';
import PropTypes from 'prop-types';
import logo from '@/assets/images/logo.png';
import styles from './style.module.css';

const PageHead = (props) => {
  const {
    title,
    subtitle = [],
    actions,
    sticky = true,
  } = props;

  const classes = clsx(sticky && styles.sticky);

  return (
    <Container fluid className={classes}>
      <div className={styles.pagehead}>
          <header className={styles.info}>
            {title && <h1>{title}</h1>}
            {Array.isArray(subtitle)
              ? subtitle.map((text, index) => <Flex mt='sm' key={index}>{text}</Flex>)
              : <Flex mt='sm'>{subtitle}</Flex>
          }
        </header>

        {actions && <div className={styles.actions}>{actions}</div>}
        <div className={styles.logo}>
           <img src={logo} aria-label="logo" />
        </div>
      </div>
    </Container>
  );
};

PageHead.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.string,
  actions: PropTypes.any,
  sticky: PropTypes.bool,
};

export default PageHead;
