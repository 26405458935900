import { headers as defaultHeaders } from '@/config/axios';
import axiosRequest from '.';

const findByAccessor = async (data) => axiosRequest({
  url: '/v1/header/findByAccessor',
  method: 'POST',
  data,
  headers: defaultHeaders,
});

export {
  findByAccessor,
};
