import PropTypes from 'prop-types';
import prepareTranslate from '@/helpers/dictionary';
import companyQuery from '@/api/queries/company';
import productQuery from '@/api/queries/product';
import { useState } from 'react';
import { ACCORD_TYPE, CLIENT_PRODUCTS } from '@/helpers/enums';
import {
  Button,
  Grid,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import PageTemplate from '../PageTemplate';
import Section from '../Section';
import CustomCollapse from '../CustomCollapse';

const CreateClients = (props) => {
  const {
    form,
  } = props;

  const t = prepareTranslate();

  const [openedCollapse, setOpenedCollapse] = useState(-1);

  const { data: companies } = companyQuery.useGetAll();
  const { data: products } = productQuery.useGetAll();

  const filteredProducts = products?.filter(
    (product) => Object.values(CLIENT_PRODUCTS).includes(product.name),
  );

  const productsOptions = filteredProducts?.map((product) => ({
    label: product.hebrew,
    value: product._id,
  }));

  const companiesOptions = companies?.map((company) => ({
    label: company.hebrew,
    value: company._id,
  }));

  const handleCollapseManager = (index) => {
    let setNumber = index;

    if (openedCollapse === index) {
      setNumber = -1;
    }

    setOpenedCollapse(setNumber);
  };

  const handleDeleteCollapseManager = (index) => {
    form.removeListItem('clients', index);
  };

  const handleAddClient = () => {
    form.insertListItem('clients', {
      clientNumber: '',
      accordType: ACCORD_TYPE[0].value,
    });
    setOpenedCollapse(form.getValues().clients.length - 1);
  };

  return (
    <PageTemplate.Panel id='clients'>
    <Section title={t('components.createClients.header')}>
      {form.getValues().clients?.map((item, index) => (
        <CustomCollapse
        title={`תיק מספר ${index + 1}`}
        onDelete={() => handleDeleteCollapseManager(index)}
        onClick={() => handleCollapseManager(index)}
        open={index === openedCollapse}
        key={index + 1}
      >
        <Grid gutter={{ base: 'xl' }} key={index + 1}>
          <Grid.Col span={{ base: 4 }}>
            <Select
              label={t('components.createClients.company')}
              key={form.key(`clients.${index}.companyId`)}
              data={companiesOptions}
              {...form.getInputProps(`clients.${index}.companyId`)}
              />
            <TextInput
              autoComplete='off'
              maxLength={25}
              label={t('components.createClients.hp')}
              key={form.key(`clients.${index}.hp`)}
              {...form.getInputProps(`clients.${index}.hp`)}
            />
          </Grid.Col>
            <Grid.Col span={{ base: 4 }}>
            <Select
                    required
                    label={t('components.createClients.branch')}
                    data={productsOptions}
                    key={form.key(`clients.${index}.branchId`)}
                    {...form.getInputProps(`clients.${index}.branchId`)}
                  />
            <Select
              required
              label={t('components.createClients.accordType')}
              key={form.key(`clients.${index}.accordType`)}
              defaultValue={ACCORD_TYPE[1].value}
              data={ACCORD_TYPE}
              {...form.getInputProps(`clients.${index}.accordType`)}
            />
          </Grid.Col>
            <Grid.Col span={{ base: 4 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createClients.number')}
              key={form.key(`clients.${index}.clientNumber`)}
              maxLength={7}
              required
              {...form.getInputProps(`clients.${index}.clientNumber`)}
            />
            <NumberInput
              autoComplete='off'
              label={t('components.createClients.commission')}
              key={form.key(`clients.${index}.agentCommission`)}
              maxLength={7}
              {...form.getInputProps(`clients.${index}.agentCommission`)}
            />
          </Grid.Col>
        </Grid>
      </CustomCollapse>
      ))}
      <Button
        mt={15}
        mb={15}
        onClick={handleAddClient}
      >
        {t('components.createClients.addNewClient')}
      </Button>
    </Section>
  </PageTemplate.Panel>
  );
};

CreateClients.propTypes = {
  form: PropTypes.object,
};

export default CreateClients;
