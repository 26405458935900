import { useEffect, useState } from 'react';
import { BarChart, LineChart } from '@mantine/charts';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import {
  Container,
  Group,
  LoadingOverlay,
  Select,
  Text,
} from '@mantine/core';
import { CHART_TYPES, MONTHS } from '@/helpers/enums';
import prepareTranslate from '@/helpers/dictionary';
import { isEqual } from 'lodash';
import { createYearsArray } from '@/helpers/dates';
import ErrorFallback from '../ErrorFallback';
import generateMockChartData from './mockData/mockChartData';
import mockSeries from './mockData/mockSeries';
import {
  attachColorsToSeries,
  formatData,
  prepareToolTipData,
  validateData,
} from './LineChart.functions';
import NoData from './noData';
import ChartType from './ChartType';
import styles from './style.module.css';

const chartOptions = ['Bar', 'Line'];

const ChartComponent = (props) => {
  const {
    getData = () => {},
    getDataQuery,
    dataKey = 'category',
    h = '18rem',
    curveType = 'linear',
    isChartTypeActive = true,
    useDateSelectors = false,
    onBarClick,
    defaultValue,
    defaultFilters,
    noDataAction,
  } = props;

  const mockChartData = generateMockChartData();
  const t = prepareTranslate();
  const [data, setData] = useState(mockChartData); // data from back end
  const [formattedData, setFormattedData] = useState([]); // data without components to match chart
  const [series, setSeries] = useState([]);
  const [primaryValue, setPrimaryValue] = useState();
  const [loading, setLoading] = useState(true);
  const [chartType, setChartType] = useState(chartOptions);
  const [filters, setfilters] = useState(defaultFilters);
  const [sum, setSum] = useState(0);

  const onChartTypeSelect = (value) => {
    setChartType(value);
  };

  const formatNumberWithSeparator = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const getUpdatedQuery = () => {
    const updatedQuery = { ...getDataQuery };
    if (useDateSelectors) {
      updatedQuery.month = [filters.month];
      updatedQuery.year = [filters.year];
    }
    return updatedQuery;
  };

  /**
   * Call server with missing data, and fill "data", "formattedData" and "series"  state
   */
  const fillDataFromServer = async (value) => {
    setLoading(true);
    const isdefaultvalue = isEqual(defaultFilters, filters) && value;
    const newRows = isdefaultvalue ? value : await getData(getUpdatedQuery());
    setLoading(false);

    const {
      data: newData,
      series: newSeries,
      primaryValue: newPrimaryValue,
    } = newRows;

    if (!validateData(newData, dataKey, series, newPrimaryValue)) {
      // eslint-disable-next-line no-console
      console.error('getData is not valid');
      setData([]);
      setSum(null);
      return;
    }

    const coloredSeries = attachColorsToSeries(newSeries);
    const newformattedData = formatData(newData);

    // Removes zero-value fields and filters out items with all zero values.
    const filteredData = newformattedData.map((item) => {
      const newItem = { ...item };
      let allZero = true;
      newSeries.forEach(({ name }) => {
        if (newItem[name] === 0) {
          delete newItem[name];
        } else {
          allZero = false;
        }
      });
      return allZero ? null : newItem;
    }).filter(Boolean);

    setFormattedData(filteredData);
    setData(newData);
    setSeries(coloredSeries);
    setPrimaryValue(newPrimaryValue);
    // eslint-disable-next-line max-len
    setSum(formatNumberWithSeparator(Math.floor(filteredData?.map((item) => Object.values(item)[0]).reduce((acc, itemValue) => acc + itemValue, 0))));
  };

  useEffect(() => {
    fillDataFromServer(defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataQuery, filters]);

  const chartData = loading ? mockChartData : formattedData;
  const currentSeries = loading ? mockSeries : series;
  const emptyData = (!loading && data.length === 0);
  const valueFormatter = (value) => new Intl.NumberFormat('en-US').format(Math.floor(value));

  return (
    <Container pos='relative'>
      <LoadingOverlay
        visible={loading}
      />
      <Group >
      {isChartTypeActive && <Container mr={0} p={'1.5rem'} w={'10rem'}>
           <ChartType onChartTypeSelect={onChartTypeSelect} />
      </Container>}
      </Group>
      {useDateSelectors && (
        <>
        <div className={styles.selectDateContainer} >
    <Select
      w="7rem"
      data={MONTHS}
      value={filters.month}
      onChange={(value) => setfilters({ ...filters, month: value })}
    />
    <Select
      w="7rem"
      autoComplete="off"
      data={createYearsArray()}
      value={filters.year}
      onChange={(value) => setfilters({ ...filters, year: value })}
    />
          </div>
          {sum && <Text ta='center' mt='s' mb='xl' pt={'1rem'}>{t('components.chart.sum', [sum])}</Text>
          }
        </>
      )}
      {
        // eslint-disable-next-line no-nested-ternary
        emptyData ? (
          <NoData noDataAction={noDataAction} title={t('components.chart.noRows.title')} />
        ) : (
          chartType === CHART_TYPES.line ? (
            <LineChart
              h={h}
              data={chartData}
              dataKey={dataKey}
              curveType={curveType}
              series={currentSeries}
              withXAxis={!loading}
              withYAxis={!loading}
              withLegend={!loading}
              withTooltip={!loading}
              tooltipProps={{
                content: ({ label, payload }) => (
                  prepareToolTipData(data, label, payload, primaryValue)
                ),
              }}
              valueFormatter={valueFormatter}
            />
          ) : (
          <>
          <BarChart
            h={h}
            data={chartData}
            dataKey={dataKey}
            series={currentSeries}
            withLegend={!loading && !useDateSelectors}
                    tooltipProps={{
                      content: ({ label, payload }) => (
                        prepareToolTipData(data, label, payload, primaryValue)
                      ),
                    }}
            barProps={(item) => ({
              ...item,
              onClick: (bar) => {
                onBarClick(getUpdatedQuery(), bar);
              },
              className: onBarClick ? styles.bar : '',
            })}
            valueFormatter={valueFormatter}
                  />
            </>
          )
        )
      }
    </Container>
  );
};

ChartComponent.propTypes = {
  getData: PropTypes.func,
  getDataQuery: PropTypes.object,
  dataKey: PropTypes.string,
  h: PropTypes.number,
  curveType: PropTypes.string,
  isChartTypeActive: PropTypes.bool,
  useDateSelectors: PropTypes.bool,
  defaultMonth: PropTypes.string,
  defaultYear: PropTypes.string,
  onBarClick: PropTypes.func,
  defaultValue: PropTypes.object,
  defaultFilters: PropTypes.object,
  noDataAction: PropTypes.string,
};

export default withErrorBoundary(ChartComponent, {
  FallbackComponent: ErrorFallback,
});
