export default {
  title: 'הוספת עמלות סוכנות',
  side: {
    tabs: 'מידע נוסף',
    comments: 'הערות',
    actions: 'פעולות',
  },
  form: {
    company: 'חברה מנהלת',
    product: 'מוצר',
    agencyFeeMonthlyDeposit: 'עמלת סוכנות נפרעים מהפקדה %',
    agencyFeeMonthlyAccumulation: 'עמלת סוכנות נפרעים מצבירה %',
    agencyFeeAnnualAccumulation: 'עמלת סוכנות היקף מצבירה %',
    agencyFeeAnnualDeposit: 'עמלת סוכנות היקף מהפקדה %',
  },
  notification: {
    success: {
      title: 'עמלות הסוכנןת נוספו בהצלחה',
      message: 'פרטי עמלות הסוכנות נוספו במערכת',
    },
    failure: {
      title: 'הפרטים לא נוספו',
      message: 'אירעה שגיאה בניסיון העדכון',
    },
    validtion: {
      title: 'הפרטים לא נוספו',
      message: 'אחד מהפרטים שהוזנו איזו תקין',
    },
  },
};
