import Comments from '@/components/Comments';
import prepareTranslate from '@/helpers/dictionary';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageTemplate from '@/components/PageTemplate';
import { Button } from '@mantine/core';
import { routeWithParams } from '@/helpers/route';
import routes from '@/routes';
import { IconEdit } from '@tabler/icons-react';

function Template(props) {
  const {
    children,
  } = props;

  const t = prepareTranslate();
  const { id } = useParams();

  const tabs = [
    {
      title: t('pages.agencyCommissionsView.side.comments'),
      content: <Comments type='agencyCommissions' id={id} showForm />,
      isDefault: true,
    },
  ];

  const navigate = useNavigate();

  const handleUpdateClick = () => {
    navigate(routeWithParams(routes.agencyCommissionsEdit.path, { id }));
  };

  return (
    <PageTemplate
    tabs={tabs}
    tabsTitle={t('pages.agencyCommissionsView.side.tabs')}
      actionsTitle={t('pages.agencyCommissionsView.side.actions')}
      title={t('pages.agencyCommissionsView.title')}
        headerActions={
          <Button onClick={handleUpdateClick} leftSection={<IconEdit />}>
            {t('pages.agencyCommissionsView.action')}
          </Button>
        }
  >
    {children}
  </PageTemplate>
  );
}

Template.propTypes = {
  children: PropTypes.object,
};

export default Template;
