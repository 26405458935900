import prepareTranslate from '@/helpers/dictionary';
import { PROCESS_NOTIFICATION_SCREENS } from '@/helpers/enums';
import {
  Badge,
  Button,
  Center,
  Container,
  Flex,
  Text,
} from '@mantine/core';
import PropTypes from 'prop-types';

function DisplayAgent(props) {
  const {
    currentIdentifier,
    currentIdentifierSpot,
    setPage,
    clientOrAgreement,
    createdAgent,
    identifiers,
  } = props;

  const {
    company,
    product,
    identifierNumber,
    year,
    month,
  } = currentIdentifier;

  const t = prepareTranslate();

  // eslint-disable-next-line max-len
  const createAgreementButtonText = clientOrAgreement === PROCESS_NOTIFICATION_SCREENS.createAgreement
    ? t('components.processNotification.popUp.createAgreement')
    : t('components.processNotification.popUp.createClient');

  const clientOrAgreementInText = clientOrAgreement === PROCESS_NOTIFICATION_SCREENS.createAgreement
    ? t('components.processNotification.popUp.agreements')
    : t('components.processNotification.popUp.clients');

  const identifiersLength = identifiers.length;

  return (
    <Container fluid>
      <Center mb='lg'>
        <Text>
          {`${identifiersLength} / ${currentIdentifierSpot}`}
        </Text>
      </Center>
      <Flex justify='center' gap='lg' mb='lg'>
        <Badge
          variant='light'
          c='inherit'
          size='lg'
        >
          {`${t('components.processNotification.popUp.company')} ${company.hebrew}`}
        </Badge>
        <Badge
          variant='light'
          c='inherit'
          size='lg'
        >
          {`${t('components.processNotification.popUp.product')} ${product.hebrew}`}
        </Badge>
        <Badge
          variant='light'
          c='inherit'
          size='lg'
        >
          {`${t('components.processNotification.popUp.year')} ${year}`}
        </Badge>
        <Badge
          variant='light'
          c='inherit'
          size='lg'
        >
          {`${t('components.processNotification.popUp.month')} ${month}`}
        </Badge>
      </Flex>
      <Center mb='lg'>
        <Text size='lg'>{t('components.processNotification.popUp.message', [identifierNumber, clientOrAgreementInText])}</Text>
      </Center>
      <Flex justify='center' gap='lg' mt='xl'>
          <Button size="sm" onClick={() => setPage(1)} disabled={createdAgent} >
            {t('components.processNotification.popUp.createAgent')}
          </Button>
          <Button size="sm" onClick={() => setPage(clientOrAgreement)}>
            {createAgreementButtonText}
          </Button>
      </Flex>
      <Center>
      </Center>
    </Container>
  );
}

DisplayAgent.propTypes = {
  currentIdentifier: PropTypes.shape({
    company: PropTypes.shape({
      hebrew: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      hebrew: PropTypes.string.isRequired,
    }).isRequired,
    identifierNumber: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
  }).isRequired,
  currentIdentifierSpot: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  clientOrAgreement: PropTypes.oneOf(Object.values(PROCESS_NOTIFICATION_SCREENS)).isRequired,
  createdAgent: PropTypes.any,
  identifiers: PropTypes.arrayOf(PropTypes.shape({
    company: PropTypes.shape({
      hebrew: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      hebrew: PropTypes.string.isRequired,
    }).isRequired,
    identifierNumber: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
  })).isRequired,
};

export default DisplayAgent;
