import { useNavigate, useParams } from 'react-router-dom';
import prepareTranslate from '@/helpers/dictionary';
import { Badge, Button, Loader } from '@mantine/core';
import Comments from '@/components/Comments';
import routes from '@/routes';
import { IconEdit } from '@tabler/icons-react';
import { routeWithParams } from '@/helpers/route';
import PageTemplate from '@/components/PageTemplate';
import SectionView from '@/components/SectionView';
import { useEffect, useState } from 'react';
import { formatDateToString } from '@/helpers/string';
import userMutations from '@/api/mutitations/user';
import Messages from '../../components/Messages';

const Userview = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState('');
  const t = prepareTranslate();
  const { id } = useParams();
  const { useGetOne } = userMutations;
  const getOne = useGetOne();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const result = await getOne.mutateAsync({ id });
        setUserData(result.user);
      } catch (error) {
        navigate(routes.users.path);
      }
    };

    fetchUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!userData) {
    return <Loader />;
  }

  const {
    firstName,
    lastName,
    role,
    email,
    phone,
    isBlocked,
    isArchived,
    lastLoginAttempt,
    loginAttempts,
    passwordLastChanged,
    updatedAt,
  } = userData;

  const fullName = `${firstName} ${lastName}`;

  const statusTitle = isArchived ? t('global.status.inactive') : t('global.status.active');

  const tabs = [
    {
      title: t('pages.userview.side.comments'),
      content: <Comments type="user" id={id} showForm />,
      isDefault: true,
    },
    {
      title: t('pages.userview.side.messages'),
      content: <Messages type="user" id={id} email={email} phone={phone} showForm />,
    },
  ];

  const detailsLines = [
    {
      title: t('pages.userview.labels.firstname'),
      value: firstName,
    },
    {
      title: t('pages.userview.labels.lastname'),
      value: lastName,
    },
    {
      title: t('pages.userview.labels.role'),
      value: role,
      transforms: [
        {
          func: 'badge',
          params: {
            rules: [
              { byValue: 'administrator', color: 'blue' },
              { byValue: 'superadmin', color: 'violet' },
            ],
          },
        },
      ],
    },
  ];

  const contactLines = [
    {
      title: t('pages.userview.labels.email'),
      value: email,
      transforms: ['email'],
    },
    {
      title: t('pages.userview.labels.phone'),
      value: phone,
      transforms: ['phone'],
    },
  ];

  const infoLines = [
    {
      title: t('pages.userview.labels.block'),
      value: isBlocked,
      transforms: [
        { func: 'yesno', params: { yes: { title: 'משתמש חסום', color: 'red' }, no: { title: 'משתמש לא חסום', color: 'blue' } } },
      ],
    },
    {
      title: t('pages.userview.labels.lastLoginAttempt'),
      value: lastLoginAttempt,
      transforms: ['timeago'],
    },
    {
      title: t('pages.userview.labels.loginAttempts'),
      value: loginAttempts,
    },
    {
      title: t('pages.userview.labels.passwordLastChanged'),
      value: passwordLastChanged,
      transforms: [
        { func: 'date' },
      ],
    },
  ];

  const handleUpdateClick = () => {
    navigate(routeWithParams(routes.userEdit.path, { id }));
  };

  return (
    <>
      <PageTemplate tabs={tabs} tabsTitle={t('pages.userview.side.tabs')}
         title={<>
          {t('pages.userview.title', [fullName])}
          <Badge color="blue">{statusTitle}</Badge>
        </>}
        subtitle={t('pages.userview.subtitle', [formatDateToString(updatedAt, true)])}
        headerActions={<Button onClick={handleUpdateClick} leftSection={<IconEdit />}>{t('pages.userview.action')}</Button>}
      >
        <PageTemplate.Panel>
          <SectionView title={t('pages.userview.headers.details')} lines={detailsLines} />
          <SectionView title={t('pages.userview.headers.contact')} lines={contactLines} />
          <SectionView title={t('pages.userview.headers.info')} lines={infoLines} />
        </PageTemplate.Panel>
      </PageTemplate>
    </>
  );
};

export default Userview;
