import PropTypes from 'prop-types';
import { Switch } from '@mantine/core';
import prepareTranslate from '@/helpers/dictionary';
import styles from './style.module.css';

const AgencyCommissionsSwitch = (props) => {
  const { form } = props;
  const t = prepareTranslate();
  return (
        <Switch
            size="lg"
            onLabel={t('global.yes')}
            offLabel={t('global.no')}
            label={t('components.agencyCommissionsSwitch.areFeesIncludingVAT')}
            checked={form.values.areFeesIncludingVAT}
            labelPosition="left"
            {...form.getInputProps('areFeesIncludingVAT')}
            classNames={{
              body: styles.body,
              track: styles.track,
              label: styles.label,
              trackLabel: styles.trackLabel,
            }}
        />
  );
};

export default AgencyCommissionsSwitch;

AgencyCommissionsSwitch.propTypes = {
  form: PropTypes.object,
};
