import { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@mantine/core';
import { IconTablePlus, IconArrowNarrowLeft, IconLogout } from '@tabler/icons-react';
import { useIsMutating } from '@tanstack/react-query';
import authApi from '@/api/services/auth';
import { useNavigate } from 'react-router-dom';
import routes from '@/routes';
import useDrawer from '@/hooks/useDrawer';
import NavbarLink from '../NavbarLink';
import styles from './style.module.css';
import Import from '../Import';

const Navbar = ({ links = [] }) => {
  const [expanded, setExpanded] = useState(false);
  const isImportMutating = useIsMutating({ mutationKey: ['docs', 'import'] });
  const navigate = useNavigate();
  const { opened, close, open } = useDrawer();

  const handleImportClick = () => {
    open();
  };

  const toggleNavbar = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <Drawer opened={opened} onClose={close} closeOnClickOutside={!isImportMutating} withCloseButton={!isImportMutating} closeOnEscape={!isImportMutating} title="טעינת נתונים">
        <Import />
      </Drawer>

      <nav className={`${styles.navbar} ${expanded ? styles.expanded : ''}`}>
        <button
          className={`${styles.expandedButton} ${expanded ? styles.expanded : ''}`}
          onClick={toggleNavbar}
      >
          <IconArrowNarrowLeft className={`${expanded ? styles.iconRotated : styles.iconDefault}`} stroke={2} size={16}/>
      </button>

        <div className={styles.inner}>
          <NavbarLink icon={IconTablePlus} label="טעינת נתונים" onClick={handleImportClick} expanded={expanded} />
          {links.map((link) => (
            <NavbarLink {...link} key={link.label} expanded={expanded} />
          ))}
          <NavbarLink
            icon={IconLogout}
            label="התנתק מהמערכת"
            onClick={() => {
              authApi.logout();
              navigate(routes.login.path);
            }}
            expanded={expanded}
          />
        </div>
      </nav>
    </>
  );
};

Navbar.propTypes = {
  links: PropTypes.array,
};

export default Navbar;
