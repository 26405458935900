import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import prepareTranslate from '@/helpers/dictionary';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronRight } from '@tabler/icons-react';
import agentMutations from '@/api/mutitations/agent';
import { IDENTIFIER_QUERIES, PROCESS_NOTIFICATION_SCREENS } from '@/helpers/enums';
import { useForm } from '@mantine/form';
import validations from '@/helpers/validation';
import {
  Modal,
  Text,
  UnstyledButton,
  ScrollArea,
  ActionIcon,
  LoadingOverlay,
  Center,
} from '@mantine/core';
import agentEditValidations from '@/helpers/validation/pages/agentEdit';
import { notifications } from '@mantine/notifications';
import processInitiatorMutations from '@/api/mutitations/processInitiator';
import DisplayAgent from './DisplayAgent';
import CreateAgentInProcess from './CreateAgentInProcess';
import styles from './style.module.css';
import CreateClientOrAgreement from './CreateClientOrAgreement';

function ProcessNotification(props) {
  const {
    identifiers,
    ruleDetails,
    notificationId,
  } = props;

  const {
    title: ruleTitle,
    id: ruleId,
  } = ruleDetails;

  const { year: firstIdentifierYear, month: firstIdentifierMonth } = identifiers[0];

  const t = prepareTranslate();

  // eslint-disable-next-line max-len
  const { mutateAsync: createAgentMutate, isPending: createAgentPending } = agentMutations.useCreateAgent();
  const { mutateAsync: updateAgentMutate } = agentMutations.useUpdateAgent();
  // eslint-disable-next-line max-len
  const { mutateAsync: createAndDeleteProcessInitMutate } = processInitiatorMutations.useCreateAndDelete();

  const [opened, { close, open }] = useDisclosure(false);
  const [loading, { close: loadingOff, open: loadingOn }] = useDisclosure(false);

  // null or agentId
  const [createdAgent, setCreatedAgent] = useState(null);
  const [page, setPage] = useState(PROCESS_NOTIFICATION_SCREENS.dispalyAgent);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState(false);

  const form = useForm({
    initialValues: {
      personalDetails: {
        children: [],
      },
      agreements: [
        {
          branchId: [],
        },
      ],
      clients: [{ client: '' }],
    },
    validate: {
      agreements: {
        agentNumber: (value) => validations.required(value),
        from: (value) => validations.required(value),
        companyId: (value) => validations.required(value),
        branchId: (value) => validations.required(value),
        accordType: (value) => validations.required(value),
      },
      clients: {
        companyId: (_, values, path) => agentEditValidations.companyOrHp(values, path),
        hp: (_, values, path) => agentEditValidations.companyOrHp(values, path),
        accordType: (value) => validations.required(value),
        clientNumber: (value) => validations.required(value),
        branchId: (value) => validations.required(value),
      },
    },
  });

  const handleSubmit = async (agentValues) => {
    try {
      loadingOn();
      const formData = agentValues;
      const { agreements, clients } = formData;

      delete formData.personalDetails.createdAt;
      delete formData.personalDetails.updatedAt;
      delete formData.personalDetails.homeFullAddress;
      delete formData.personalDetails.officeFullAddress;
      delete formData.personalDetails.fullName;

      // // Remove the createdAt field from each item
      const sanitizedData = agreements.map((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item.createdAt;
        // eslint-disable-next-line no-param-reassign
        delete item.updatedAt;
        // eslint-disable-next-line no-param-reassign
        delete item.datesArray;

        return item;
      });

      // // Remove the createdAt field from each item
      const clientsSanitizedData = clients.map((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item.createdAt;
        // eslint-disable-next-line no-param-reassign
        delete item.updatedAt;
        return item;
      });

      formData.agreements = sanitizedData;
      formData.clients = clientsSanitizedData;

      const newAgent = await updateAgentMutate(formData);
      const nextIndex = currentIndex + 1;

      if (!newAgent) {
        loadingOff();
        setError(true);
      } else if (nextIndex <= (identifiers.length - 1)) {
        setPage(PROCESS_NOTIFICATION_SCREENS.dispalyAgent);
        setCurrentIndex(nextIndex);
        setCreatedAgent(null);
        setError(false);
      } else {
        setPage(PROCESS_NOTIFICATION_SCREENS.done);
        const processInitiatorObj = {
          year: firstIdentifierYear,
          month: firstIdentifierMonth,
          rule: ruleId,
        };
        createAndDeleteProcessInitMutate(processInitiatorObj);
        notifications.update({
          id: notificationId,
          autoClose: 2000,
          color: 'blue',
          title: t('components.doneCreatingInProcess.title'),
        });
      }
    } catch {
      setError(true);
    }

    loadingOff();
  };

  useEffect(() => {
    setError(false);
  }, [page]);

  const disabled = createAgentPending;
  // After were done creating all the agents and accords, identifiers[currentIndex]
  // wull be undefined.
  const currentIdentifier = identifiers[currentIndex] || {};

  const clientOrAgreement = currentIdentifier.identifierQuery === IDENTIFIER_QUERIES.agreement
    ? PROCESS_NOTIFICATION_SCREENS.createAgreement
    : PROCESS_NOTIFICATION_SCREENS.createClient;

  const errorMessage = currentIdentifier.identifierQuery === IDENTIFIER_QUERIES.agreement
    ? t('components.processNotification.errors.agreement')
    : t('components.processNotification.errors.client');

  const displayBackButtonTerm = (
    page !== PROCESS_NOTIFICATION_SCREENS.dispalyAgent
    && page !== PROCESS_NOTIFICATION_SCREENS.done
  );

  const displayAutoComplete = (
    page === PROCESS_NOTIFICATION_SCREENS.createClient
    || page === PROCESS_NOTIFICATION_SCREENS.createAgreement
  );

  const notificationTitle = page === PROCESS_NOTIFICATION_SCREENS.done ? t('components.doneCreatingInProcess.subTitle') : t('components.processNotification.notification.message', [ruleTitle]);

  return (
    <>
      {page !== PROCESS_NOTIFICATION_SCREENS.done && < Modal.Root
        opened={opened}
      onClose={close}
      scrollAreaComponent={ScrollArea.Autosize}
      size='lg'
      p='xl'
      zIndex={300}
      >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header className={styles.modal_header}>
          {
            displayBackButtonTerm && (
              <ActionIcon
                color='root'
                variant='transparent'
                onClick={() => setPage(0)}
                disabled={disabled}
              >
                <IconChevronRight />
              </ActionIcon>
            )
          }
          <Modal.CloseButton disabled={disabled} />
        </Modal.Header>
        <Modal.Body>
          <LoadingOverlay visible={loading} />
          {
            page === PROCESS_NOTIFICATION_SCREENS.dispalyAgent
            && <DisplayAgent
              currentIdentifier={currentIdentifier}
              currentIdentifierSpot={currentIndex + 1}
              setPage={setPage}
              clientOrAgreement={clientOrAgreement}
              createdAgent={createdAgent}
              identifiers={identifiers}
            />
          }
          {
            page === PROCESS_NOTIFICATION_SCREENS.createAgent
            && <CreateAgentInProcess
              setPage={setPage}
              createAgentMutate={createAgentMutate}
              clientOrAgreement={clientOrAgreement}
              setCreatedAgent={setCreatedAgent}
            />
          }
          <form onSubmit={form.onSubmit(handleSubmit)}>
            {
              displayAutoComplete && <CreateClientOrAgreement
                currentIdentifier={currentIdentifier}
                createdAgent={createdAgent}
                clientOrAgreement={clientOrAgreement}
                form={form}
              />
            }
          </form>
           <Center mt='lg'>
              {
                error && <Text c='red'>
                  {errorMessage}
                </Text>
              }
            </Center>
        </Modal.Body>
      </Modal.Content>
      </Modal.Root >
      }
      <Text size='sm'>
        {
          notificationTitle
        }
      </Text>
      {page !== PROCESS_NOTIFICATION_SCREENS.done && <UnstyledButton onClick={open}>
        <Text td="underline" c='blue' size='sm'>
          {
            t('components.processNotification.notification.click')
          }
        </Text>
      </UnstyledButton>
      }
    </>
  );
}

ProcessNotification.propTypes = {
  notificationId: PropTypes.string,
  identifiers: PropTypes.arrayOf(PropTypes.shape({
    company: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    identifierNumber: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    identifierQuery: PropTypes.string.isRequired,
  })).isRequired,
  ruleDetails: PropTypes.object,
  ruleFile: PropTypes.string.isRequired,
};

export default ProcessNotification;
