import { useMutation } from '@tanstack/react-query';
import { createAndDelete } from '../requests/processInitiator';

const KEY = 'processInitiator';

const useCreateAndDelete = () => useMutation({
  mutationKey: [KEY, 'createAndDelete'],
  mutationFn: (data) => createAndDelete(data),
});

const processInitiatorMutations = {
  useCreateAndDelete,
};

export default processInitiatorMutations;
