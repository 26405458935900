import { useMutation } from '@tanstack/react-query';
import {
  updateAgent,
  createAgent,
  getAgentsByQuery,
  archiveAgent,
  getAgentById,
} from '../requests/agent';

const KEY = 'agent';

const useGetAgents = () => useMutation({
  mutationKey: [KEY, 'getAgents'],
  mutationFn: ({ start, end, query }) => getAgentsByQuery({ start, end, query }),
});

const useGetOne = () => useMutation({
  mutationKey: [KEY, 'getOne'],
  mutationFn: (data) => getAgentById(data),
});

const useUpdateAgent = () => useMutation({
  mutationKey: [KEY, 'update'],
  mutationFn: (data) => updateAgent(data),
});

const useCreateAgent = () => useMutation({
  mutationKey: [KEY, 'create'],
  mutationFn: (data) => createAgent(data),
});

const useArchiveAgent = () => useMutation({
  mutationKey: [KEY, 'archive'],
  mutationFn: (data) => archiveAgent(data),
});

const agentMutations = {
  useGetAgents,
  useUpdateAgent,
  useCreateAgent,
  archiveAgent,
  useArchiveAgent,
  useGetOne,
};

export default agentMutations;
