import prepareTranslate from '@/helpers/dictionary';
import { ACCORD_TYPE } from '@/helpers/enums';
import {
  Button,
  Center,
  Grid,
  MultiSelect,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import PropTypes from 'prop-types';

function CreateAgentAgreementInProcess(props) {
  const {
    form,
    productsOptions,
    companiesOptions,
  } = props;

  const t = prepareTranslate();

  const agreementIndex = form.getValues().agreements && form.getValues().agreements.length - 1;
  // eslint-disable-next-line max-len
  const agreementAccordType = form.getValues().agreements && form.getValues().agreements[agreementIndex].accordType;

  return (
    <>
      <Grid gutter='lg'>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            autoComplete='off'
            label={t('components.createAgentAgreementInProcess.company')}
            data={companiesOptions}
            withAsterisk
            maxLength={20}
            {...form.getInputProps(`agreements.${agreementIndex}.companyId`)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <TextInput
            autoComplete='off'
            withAsterisk
            label={t('components.createAgentAgreementInProcess.agent')}
            maxLength={10}
            {...form.getInputProps(`agreements.${agreementIndex}.agentNumber`)}
          />
        </Grid.Col>
      </Grid>
      <Grid gutter='lg'>
        <Grid.Col span={{ base: 6 }}>
          <MonthPickerInput
            withAsterisk
            maxDate={new Date()}
            label={t('components.createAgentAgreementInProcess.from')}
            {...form.getInputProps(`agreements.${agreementIndex}.from`)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6 }}>
          <MonthPickerInput
            label={t('components.createAgentAgreementInProcess.till')}
            {...form.getInputProps(`agreements.${agreementIndex}.till`)}
          />
        </Grid.Col>
      </Grid>
      <Grid gutter='lg'>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <MultiSelect
            withAsterisk
            label={t('components.createAgentAgreementInProcess.branch')}
            data={productsOptions}
            {...form.getInputProps(`agreements.${agreementIndex}.branchId`)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            withAsterisk
            label={t('components.createAgentAgreementInProcess.accordType')}
            data={ACCORD_TYPE}
            {...form.getInputProps(`agreements.${agreementIndex}.accordType`)}
          />
        </Grid.Col>
      </Grid>
      {
        agreementAccordType === 'independent' && (
          <Grid gutter='lg'>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <NumberInput
                autoComplete='off'
                label={t('components.createAgentAgreementInProcess.commision.agentFeeMonthlyAccumulation')}
                maxLength={5}
                min={0}
                {...form.getInputProps(`agreements.${agreementIndex}.agentFeeMonthlyAccumulation`)}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <NumberInput
                autoComplete='off'
                label={t('components.createAgentAgreementInProcess.commision.agentFeeMonthlyDeposit')}
                maxLength={5}
                min={0}
                {...form.getInputProps(`agreements.${agreementIndex}.agentFeeMonthlyDeposit`)}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <NumberInput
                autoComplete='off'
                label={t('components.createAgentAgreementInProcess.commision.agentFeeAnnualDeposit')}
                maxLength={5}
                min={0}
                {...form.getInputProps(`agreements.${agreementIndex}.agentFeeAnnualDeposit`)}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <NumberInput
                autoComplete='off'
                label={t('components.createAgentAgreementInProcess.commision.agentFeeAnnualAccumulation')}
                maxLength={5}
                min={0}
                {...form.getInputProps(`agreements.${agreementIndex}.agentFeeAnnualAccumulation`)}
              />
            </Grid.Col>
          </Grid>
        )
      }
      {
        agreementAccordType !== 'independent' && (
          <Grid gutter='lg'>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <NumberInput
                autoComplete='off'
                label={t('components.createAgentAgreementInProcess.commision.employeeAgentCommission')}
                maxLength={5}
                min={0}
                {...form.getInputProps(`agreements.${agreementIndex}.employeeAgentCommission`)}
              />
            </Grid.Col>
          </Grid>
        )
      }
       <Grid gutter='lg'>
            <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={t('components.createAgentAgreementInProcess.accordName')}
                  maxLength={200}
                    min={0}
                  {...form.getInputProps(`agreements.${agreementIndex}.accordName`)}
                   />
              </Grid.Col>
       </Grid>

      <Center mt='lg'>
        <Button
          type='submit'
        >
          {t('components.createAgentAgreementInProcess.submit')}
        </Button>
      </Center>
    </>
  );
}

CreateAgentAgreementInProcess.propTypes = {
  form: PropTypes.shape({
    setValues: PropTypes.func.isRequired,
    insertListItem: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
    getInputProps: PropTypes.func.isRequired,
  }).isRequired,
  productsOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  companiesOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  currentIdentifier: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    identifierNumber: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  createdAgent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    string: PropTypes.string.isRequired,
  }),
};

export default CreateAgentAgreementInProcess;
