export default {
  firstname: 'שם פרטי',
  lastname: 'שם משפחה',
  fullName: 'שם מלא',
  agentType: 'תפקיד',
  uid: 'תעודת זהות',
  dob: 'תאריך לידה',
  email: 'דואר אלקטרוני',
  status: 'סטטוס',
  employeeType: 'סוג סוכן',
  gender: 'מין',
  error: 'שגיאה ביצירת סוכן, אנא נסו שנית',
  minimalDetails: 'זהו מידע מינימלי ליצירת סוכן. להזנת פרטים נוספים היכנס למסך סוכנים',
};
