import { useQuery } from '@tanstack/react-query';
import { getAll } from '../requests/dashboard';

const KEY = 'dashboard';

const dashboardQuery = {
  useGetAll: (data = {}, headers = {}) => useQuery({
    queryKey: [KEY],
    queryFn: () => getAll(data, headers),
  }),
};

export default dashboardQuery;
