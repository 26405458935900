import { useNavigate, useParams } from 'react-router-dom';
import prepareTranslate from '@/helpers/dictionary';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import Comments from '@/components/Comments';
import agentMutations from '@/api/mutitations/agent';
import uploadMutations from '@/api/mutitations/upload';
import { IconCheck } from '@tabler/icons-react';
import PageTemplate from '@/components/PageTemplate';
import { useEffect, useState } from 'react';
import validations from '@/helpers/validation/index';
import agentEditValidations from '@/helpers/validation/pages/agentEdit';
import agentQuery from '@/api/queries/agent';
import uploadQuery from '@/api/queries/upload';
import { routeWithParams } from '@/helpers/route';
import { formatDateToString } from '@/helpers/string';
import { Button } from '@mantine/core';
import routes from '@/routes';
import EditAgentDetails from '@/components/EditAgentDetails';
import EditContracts from '@/components/EditContracts';
import EditDocuments from '@/components/EditDocuments';
import EditClients from '@/components/EditClients';

const Agentedit = () => {
  const t = prepareTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, { open, close }] = useDisclosure(false);

  const { data: agentData, status } = agentQuery.useGetAgentById({ id });
  const { data: uploadsData } = uploadQuery.useGetFilesById({ id });

  const { useUpdateAgent } = agentMutations;
  const { mutateAsync: updateAgentMutate } = useUpdateAgent();
  const { useUpload } = uploadMutations;
  const { mutateAsync: uploadMutate } = useUpload();

  const [agent, setAgent] = useState({ personalDetails: {} });
  const [filesOrigin, setFilesOrigin] = useState([]);
  const [files, setFiles] = useState([]);

  const form = useForm({
    initialValues: {
      personalDetails: {
        children: [],
      },
      agreements: [
        {
          branchId: [],
        },
      ],
      clients: [{ client: '' }],
    },
    validate: {
      status: (value) => validations.required(value),
      agentType: (value) => validations.required(value),
      employeeType: (value) => validations.required(value),
      personalDetails: {
        firstName: (value) => validations.required(value),
        lastName: (value) => validations.required(value),
        uid: (value) => validations.required(value) || validations.uid(value),
        gender: (value) => validations.required(value) || validations.required(value),
        dob: (value) => validations.required(value),
        email: (value) => value && validations.email(value),
        phone: (value) => value && validations.phone(value),
        extraPhone: (value) => value && validations.phone(value),
        homeZipCode: (value) => value && validations.number(Number(value)),
        homePhone: (value) => value && validations.phone(value),
        homePostalCode: (value) => value && validations.number(Number(value)),
        officeZipCode: (value) => value && validations.number(Number(value)),
        officePostalCode: (value) => value && validations.number(Number(value)),
        children: {
          childrenName: (value) => validations.required(value),
        },
      },
      agreements: {
        agentNumber: (value) => validations.required(value),
        from: (value) => validations.required(value),
        companyId: (value) => validations.required(value),
        branchId: (value) => validations.required(value),
        accordType: (value) => validations.required(value),
      },
      clients: {
        companyId: (_, values, path) => agentEditValidations.companyOrHp(values, path),
        hp: (_, values, path) => agentEditValidations.companyOrHp(values, path),
        accordType: (value) => validations.required(value),
        clientNumber: (value) => validations.required(value),
        branchId: (value) => validations.required(value),
      },
    },
  });

  useEffect(() => {
    if (!agentData && status !== 'pending') {
      navigate(routes.agents.path);
    } else {
      setFiles(uploadsData);
      setFilesOrigin(uploadsData);
      setAgent(agentData);
      const agreementsSanitizedData = agentData?.agreements?.map((item) => {
        const itemCopy = item;
        itemCopy.from = item.from ? new Date(item.from) : null;
        itemCopy.till = item.till ? new Date(item.till) : null;

        itemCopy.companyId = item.companyId?._id;
        itemCopy.branchId = item.branchId.map((branch) => branch?._id);

        return itemCopy;
      });

      const clientsSanitizedData = agentData?.clients.map((item) => {
        const itemCopy = item;
        itemCopy.companyId = item.companyId?._id;
        itemCopy.branchId = item.branchId?._id;

        return itemCopy;
      });

      form.setValues({
        id,
        personalDetails: agentData?.personalDetails,
        agreements: agreementsSanitizedData,
        status: agentData?.status,
        clients: clientsSanitizedData,
        agentType: agentData?.agentType,
        employeeType: agentData?.employeeType,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData, status]);

  const onError = () => {
    open();
    notifications.show({
      title: t('pages.agentEdit.notification.validtion.title'),
      message: t('pages.agentEdit.notification.validtion.message'),
    });
    close();
  };
  // TODO
  // eslint-disable-next-line consistent-return
  const handleAgentUpdateClick = async (agentValues) => {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    const userCookie = cookies.find((cookie) => cookie.startsWith('userInfo=')) || '';
    const userCookieValue = { entity: 'agent', entityId: id, ...JSON.parse(decodeURIComponent(userCookie.replace('userInfo=', ''))) };

    try {
      open();
      if (files.length > 0 && filesOrigin.length !== files.length) {
        try {
          const formData = new FormData();

          const fillterdFiles = files.filter((file) => !file._id);

          fillterdFiles.forEach((file) => {
            formData.append('files', file, file?.name);
          });

          formData.append('user', JSON.stringify(userCookieValue));
          await uploadMutate(formData);
        } catch (error) {
          notifications.show({
            title: t('pages.agentEdit.notification.failure.title'),
            message: t('pages.agentEdit.notification.failure.message'),
          });
          close();
        }
      }

      const formData = agentValues;
      const { agreements, clients } = formData;

      delete formData.personalDetails.createdAt;
      delete formData.personalDetails.updatedAt;
      delete formData.personalDetails.homeFullAddress;
      delete formData.personalDetails.officeFullAddress;
      delete formData.personalDetails.fullName;

      // // Remove the createdAt field from each item
      const sanitizedData = agreements.map((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item.createdAt;
        // eslint-disable-next-line no-param-reassign
        delete item.updatedAt;
        // eslint-disable-next-line no-param-reassign
        delete item.datesArray;

        return item;
      });
      // // Remove the createdAt field from each item
      const clientsSanitizedData = clients.map((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item.createdAt;
        // eslint-disable-next-line no-param-reassign
        delete item.updatedAt;

        return item;
      });

      formData.agreements = sanitizedData;
      formData.clients = clientsSanitizedData;

      const newAgent = await updateAgentMutate(formData);
      if (!newAgent) {
        notifications.show({
          title: t('pages.agentEdit.notification.failure.title'),
          message: t('pages.agentEdit.notification.failure.message'),
        });
        close();
        return null;
      }

      notifications.show({
        title: t('pages.agentEdit.notification.success.title'),
        message: t('pages.agentEdit.notification.success.message'),
      });
      navigate(routeWithParams(routes.agentView.path, { id }));
      close();
    } catch (error) {
      close();
    }
  };

  const handleAgentCancelClick = () => {
    navigate(routes.agents.path);
  };

  const menuGroups = [
    {
      links: [
        {
          id: 'details',
          title: t('pages.agentEdit.menu.details'),
        },
        {
          id: 'contracts',
          title: t('pages.agentEdit.menu.contracts'),
        },
        {
          id: 'documents',
          title: t('pages.agentEdit.menu.documents'),
        },
        {
          id: 'clients',
          title: t('pages.agentEdit.menu.clients'),
        },
      ],
    },
  ];

  const tabs = [
    {
      title: t('pages.agentEdit.side.comments'),
      content: <Comments type='agent' id={id} showForm />,
      isDefault: true,
    },
  ];

  const actions = [
    <Button
      key='primary'
      loading={loading}
      leftSection={<IconCheck />}
      onClick={form.onSubmit(handleAgentUpdateClick, onError)}
    >
      {t('global.update')}
    </Button>,
    <Button key='cancel' variant='outline' onClick={handleAgentCancelClick}>
      {t('global.cancel')}
    </Button>,
  ];

  return (
    <>
      <PageTemplate
        menuGroups={menuGroups}
        tabs={tabs}
        tabsTitle={t('pages.agentEdit.side.tabs')}
        actions={actions}
        actionsTitle={t('pages.agentEdit.side.actions')}
        title={t('pages.agentEdit.title', [
          agent?.personalDetails?.fullName || '',
        ])}
        subtitle={t('pages.agentEdit.subtitle', [formatDateToString(agent?.updatedAt)])}
      >

        <EditAgentDetails
          form={form}
        />

        <EditContracts
          form={form}
        />

        <EditDocuments
          form={form}
          files={files}
          setFiles={setFiles}
          open={open}
          close={close}
        />

        <EditClients
          form={form}
        />
      </PageTemplate>
    </>
  );
};

export default Agentedit;
