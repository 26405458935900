export default {
  title: 'טעינת נתונים',
  subtitle: 'מלא את כל הנתונים הנדרשים על מנת לטעון נתונים למערכת',
  dropzone: {
    title: 'גרור את הקבצים, או לחץ כאן כדי לבחור אותם',
    subtitle: 'ניתן להעלות קבצים בגודל של עד',
  },
  notifications: {
    success: 'הצלחה בטעינת הנתונים',
    validationError: 'כשלון בטעינת הנתונים',
    internalError: 'כשלון בטעינת הנתונים, אנא רענן את הדף',
  },
  form: {
    company: 'חברה מנהלת',
    files: 'קובץ',
    month: 'חודש',
    year: 'שנה',
    action: 'טען נתונים',
  },
  errors: {
    fileTitleInvalid: 'שם הקובץ שנבחר אינו תואם את שמות הקבצים הנדרשים',
    required: 'שדה חובה',
    'file-invalid-type': 'סוג המסמך לא תואם את הסוג הנדרש',
    'file-too-large': 'המסמך גדול מדי',
  },
};
