import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Group,
  Pagination,
  Table,
  Text,
} from '@mantine/core';
import {
  isArray,
  isFunction,
  isNumber,
  isObject,
} from '@/helpers/is';
import { DIRECTIONS } from '@/helpers/enums';
import { queryStringToObject } from '@/helpers/string';
import { withErrorBoundary } from 'react-error-boundary';
import { createRangeFromArrays } from '@/helpers/dates';
import prepareTranslate from '@/helpers/dictionary';
import { isValid, parse } from 'date-fns';
import styles from './style.module.css';
import Filter from './Filter';
import Export from './Export';
import Columns from './Columns';
import Rows from './Rows';
import {
  calculateTotalPages,
  getCleanQuery,
  getDirtyQuery,
  getPageCountDetails,
  getRangeDataByNumber,
  getRowsData,
  getRowsIndexes,
  validateData,
} from './Table.functions';
import ErrorFallback from '../ErrorFallback';
import Th from './Th';

const FILTER_PREFIX = 'filter_';
const CHECKBOX_COLUMN_WIDTH = '50px';

// TODO: add sort ability

const TableComponent = (props) => {
  const {
    columns = [],
    withPagination = true,
    onExport = () => {}, // function signature: (option: {string}, query: {object})
    onColumns = () => {},
    isExportActive = true,
    isColumnsActive = true,
    isFilterActive = true,
    getData = () => {}, // function signature (start: {number}, end: {number}, query: {object})
    rowsPerPage = 10,
    selectionActions = [],
    childElement: ChildElement = () => {},
    childGetData = () => {},
    exportOptions = null,
    childProps,
    noDataAction,
  } = props;

  const { query: rawUrlQuery } = useParams();
  const [values, setValues] = useState([]); // full rows data
  const [rows, setRows] = useState([]); // table rows on page
  const [loading, setLoading] = useState(false); // loading
  const [page, setPage] = useState(1); // page number
  const [query, setQuery] = useState({}); // filter query
  const [formattedQueryForChild, setFormattedQueryForChild] = useState({}); // formated for child
  const [totalRows, setTotalRows] = useState(0); // total number of rows
  const [totalPages, setTotalPages] = useState(0); // total page count
  const [selection, setSelection] = useState([]); // total page count
  const [sortAction, setSortAction] = useState({ title: null, direction: null }); // sort by
  const t = prepareTranslate();

  const isAllowCheck = !!(isArray(selectionActions) && selectionActions.length);

  const actions = selectionActions.map((action) => {
    const {
      title = null,
      icon = null,
      onClick,
    } = action;

    const handleActionClick = async () => {
      if (isFunction(onClick)) {
        await onClick(selection);
      }
    };

    return <Button
      key={title}
      onClick={handleActionClick}
      size="xs"
      variant="outline"
      leftSection={icon}
    >
      {title}
    </Button>;
  });

  /**
   * go over "values" state and check there are data in start and end indexes.
   * call server with missing data, and fill "values" state
   * @param {number} start
   * @param {number} end
   */
  const fillDataFromServer = async (start, end) => {
    if (!isNumber(start) || !isNumber(end)) {
      // eslint-disable-next-line no-console
      console.error('One of the variables (start or end) is not a number. cant fill data from server');
    }

    let index = start - 1;
    const newValues = values; // start with actual data (values)
    const startExists = isObject(newValues[start - 1]);
    const endExists = isObject(newValues[end - 1]);
    let formattedQuery;
    let filterQuery;

    // check if we need to bring new values
    if (!startExists || (end <= totalPages && !endExists)) {
      setLoading(true);
      const startRange = getRangeDataByNumber(start);
      const endRange = getRangeDataByNumber(end);
      const startIndex = Math.min(...startRange, ...endRange);
      const endIndex = Math.max(...startRange, ...endRange);

      if (rawUrlQuery) {
        formattedQuery = queryStringToObject(rawUrlQuery);
        const {
          month,
          year,
          agent,
          company,
          product,
        } = formattedQuery;

        const [firstDate, lastDate] = createRangeFromArrays(year, month);

        filterQuery = {
          company,
          product,
          identifier: agent,
          date: [firstDate, lastDate],
        };

        setFormattedQueryForChild(formattedQuery);
        setQuery(getDirtyQuery(filterQuery));
      } else {
        formattedQuery = getCleanQuery(query);
      }

      const newRows = await getData(startIndex, endIndex, formattedQuery);
      setLoading(false);

      if (!newValues) {
        // eslint-disable-next-line no-console
        console.error('getData not return an object {data: array, count: number}');
        return;
      }

      const {
        data,
        count,
      } = newRows;

      if (!validateData(data, count)) {
        // eslint-disable-next-line no-console
        console.error('getData is not valid');
        return;
      }

      data.forEach((element) => {
        newValues[index] = element;
        index += 1;
      });

      setValues(newValues);
      setTotalRows(count);

      const newTotalPages = calculateTotalPages(count, rowsPerPage);

      if (totalPages !== newTotalPages) {
        setTotalPages(newTotalPages);
      }
    }
  };

  /**
   * update page rows that will be visible on table.
   * this is the major function that starts everything
   */
  const updateRows = async () => {
    const [startIndex, endIndex] = getRowsIndexes(page, rowsPerPage);
    await fillDataFromServer(startIndex, endIndex);
    const newRows = getRowsData(startIndex, endIndex, values);
    setRows(newRows);
  };

  const onFilterSubmit = () => {
    // TODO: need to check how to check new query against old query
    // TODO: if no change than dont clean values
    setValues([]);
    setPage(1);
  };

  const onFilterResetAll = () => {
    setValues([]);
    setPage(1);
  };

  const onExportSubmit = async (d) => {
    let formattedQuery;

    if (rawUrlQuery) {
      formattedQuery = queryStringToObject(rawUrlQuery);
    } else {
      formattedQuery = getCleanQuery(query);
    }

    await onExport(d, formattedQuery);
  };

  const handlePaginationChange = (pageNumber) => {
    setSelection([]);
    setPage(pageNumber);
  };

  useEffect(() => {
  }, [formattedQueryForChild]);

  const handleCheckboxAllChange = () => {
    setSelection((current) => (
      current.length === rows.length
        ? []
        : rows.map((item) => (item.id || item._id))
    ));
  };

  const checkAllHeader = () => (
    (isAllowCheck && !loading && values.length)
      ? <Table.Th style={{ width: CHECKBOX_COLUMN_WIDTH }}>
          <Checkbox
            onChange={handleCheckboxAllChange}
            checked={selection.length === rows.length}
            indeterminate={selection.length > 0 && selection.length !== rows.length}
          />
        </Table.Th>
      : null
  );

  const getKeyValue = (source, path) => {
    const keys = path.split('.');
    let currentValue = source;
    while (keys.length) {
      const currentKey = keys.shift();
      if (Object.prototype.hasOwnProperty.call(currentValue, currentKey)) {
        currentValue = currentValue[currentKey];
      } else {
        currentValue = undefined;
        break;
      }
    }
    return currentValue === undefined ? '' : currentValue;
  };

  const compareBoolean = (a, b) => {
    if (a === b) return 0;
    return a ? 1 : -1;
  };

  function convertDateToDateObj(format, dateStr) {
    const parsedDate = parse(dateStr, format, new Date());
    if (!isValid(parsedDate)) {
      return null;
    }
    return parsedDate;
  }

  const compareDates = (format, first, second) => {
    const formattedFirst = convertDateToDateObj(format, first);
    const formattedSecond = convertDateToDateObj(format, second);
    return formattedFirst - formattedSecond;
  };

  const doSort = (sortObj = {}, items = []) => {
    const output = items.sort((a, b) => {
      const first = (sortObj.direction === DIRECTIONS.asc) ? a : b;
      const second = (sortObj.direction === DIRECTIONS.asc) ? b : a;
      const firstValue = getKeyValue(first, sortObj.title);
      const secondValue = getKeyValue(second, sortObj.title);

      switch (sortObj.sort?.type) {
        case 'string':
          return firstValue.localeCompare(secondValue);
        case 'number':
          return parseFloat(firstValue) - parseFloat(secondValue);
        case 'boolean':
          return compareBoolean(firstValue, secondValue);
        case 'date':
          return compareDates(sortObj.sort.format, firstValue, secondValue);
        default:
          return firstValue.localeCompare(secondValue);
      }
    });

    return output;
  };

  const handleOnSort = (value = {}) => {
    const sortedValues = doSort(value, values);
    setValues(sortedValues);
    setSortAction(value);
  };

  useEffect(() => {
    updateRows();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAction, page, values]);

  const headers = columns.map((column) => {
    const {
      header,
      accessor,
      isVisible = true,
      width = null,
      sort,
      isSortable = true,
      actions: columnActions,
    } = column;

    const key = `${header}${accessor}`;
    const isActive = !!(accessor && isSortable && !isArray(columnActions));
    return (isVisible)
      ? <Th
          key={key}
          accessor={accessor}
          style={{ width }}
          sortAction={sortAction}
          onSort={handleOnSort}
          isActive={isActive}
          sort={sort}
        >
          {header}
        </Th>
      : null;
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.actions}>
        <Group>
          {isFilterActive && <Filter
            columns={columns}
            onFilterSubmit={onFilterSubmit}
            onFilterResetAll={onFilterResetAll}
            prefix={FILTER_PREFIX}
            query={query}
            setQuery={setQuery}
            updateUrl={rawUrlQuery}
          />}
          {
          isExportActive && (
            <Export
              onExportSubmit={onExportSubmit}
              options={exportOptions}
              columns={columns}
              tableLoading={loading}
            />
          )
          }
         {isColumnsActive && <Columns columns={columns} onColumns={onColumns} />}
        </Group>

        {(selection.length > 0) && (
          <Group mt="md">
            {actions}
            <Text size="sm">
              {t('components.table.selected', [selection.length])}
            </Text>
          </Group>
        )}
      </div>

      <div className={styles.child_element}>
        {/* eslint-disable-next-line max-len */}
        <ChildElement getData={childGetData} getDataQuery={formattedQueryForChild} dropFilters={true} {...childProps} />
      </div>

      <div className={styles.table}>
        <Table verticalSpacing="lg">
          <Table.Thead>
            <Table.Tr>
              {checkAllHeader()}
              {headers}
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            <Rows
              data={rows}
              columns={columns}
              loading={loading}
              isAllowCheck={isAllowCheck}
              setSelection={setSelection}
              selection={selection}
              noDataAction = {noDataAction}
            />
          </Table.Tbody>
        </Table>
      </div>

      <div className={styles.footer}>
        <Group justify="space-between">
          {withPagination && (
            <Pagination
              total={totalPages}
              size="sm"
              value={page}
              onChange={handlePaginationChange}
            />
          )}

          {(totalRows > 0) && (
            <Text size="sm">
              {t('components.table.count', getPageCountDetails(page, rowsPerPage, totalRows))}
            </Text>
          )}
        </Group>
      </div>
    </div>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  defaultPage: PropTypes.number,
  withPagination: PropTypes.bool,
  onExport: PropTypes.func,
  onColumns: PropTypes.func,
  isExportActive: PropTypes.bool,
  isColumnsActive: PropTypes.bool,
  isFilterActive: PropTypes.bool,
  isAllowCheck: PropTypes.bool,
  getData: PropTypes.func,
  rowsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  selectionActions: PropTypes.array,
  childElement: PropTypes.object,
  childGetData: PropTypes.func,
  exportOptions: PropTypes.array,
  childProps: PropTypes.object,
  noDataAction: PropTypes.string,
};

export default withErrorBoundary(TableComponent, {
  FallbackComponent: ErrorFallback,
});
