import PropTypes from 'prop-types';
import { notifications } from '@mantine/notifications';
import productMutation from '@/api/mutitations/product';
import companyMutation from '@/api/mutitations/company';
import ProcessNotification from '@/components/processNotification';
import { useEffect } from 'react';
import prepareTranslate from '@/helpers/dictionary';
import authQuery from '@/api/queries/auth';
import { SSE_TYPES } from '../../helpers/enums';

const processSSEData = async (obj) => {
  const {
    t,
    e,
    companyMutate,
    productMutate,
    userData,
  } = obj;

  let title;
  let message;
  let color = 'blue';
  let autoClose = true;
  const notificationId = (Math.random() + 1).toString(36).substring(7);

  const {
    success,
    data,
    error,
    userId,
  } = JSON.parse(e.data);

  if (userId !== userData._id) {
    return;
  }

  if (!success) {
    color = 'red';
    const { code } = error;
    const {
      rule: ruleDetails,
      identifiers,
      collection,
      type,
    } = data;

    const ruleTitle = ruleDetails.title;

    if (code === 501 && collection === 'agents' && type === SSE_TYPES.process) {
      const unAttributedIdentifiers = [...identifiers];
      const identifiersData = [];
      try {
        for (let i = 0; i < unAttributedIdentifiers.length; i += 1) {
          const {
            additionalData: { companyId, productId },
            identifierNumber,
            identifierQuery,
            year,
            month,
          } = unAttributedIdentifiers[i];

          // eslint-disable-next-line no-await-in-loop
          const { hebrew: companyHebrew } = await companyMutate({ id: companyId });
          // eslint-disable-next-line no-await-in-loop
          const { hebrew: productHebrew } = await productMutate({ id: productId });

          identifiersData.push({
            company: { id: companyId, hebrew: companyHebrew },
            product: { id: productId, hebrew: productHebrew },
            identifierQuery,
            identifierNumber,
            year,
            month,
          });
        }
        autoClose = false;
        title = t('components.processNotification.notification.title');

        message = <ProcessNotification
          identifiers={identifiersData}
          ruleDetails={ruleDetails}
          notificationId={notificationId}
          userId={userId}
        />;
      } catch {
        color = 'red';
        title = t('global.process.failure.title');
        message = t('global.process.failure.message', [ruleTitle]);
      }
    } else {
      color = 'red';
      title = t('global.process.failure.title');
      message = t('global.process.failure.message', [ruleTitle]);
    }
  } else {
    const { type, rule: ruleDetails } = data;

    if (type === SSE_TYPES.process) {
      title = t('global.process.success.title');
      message = t('global.process.success.message', [ruleDetails.title]);
    }
  }

  notifications.show({
    id: notificationId,
    color,
    title,
    message,
    autoClose,
  });
};

const SSEHandler = (props) => {
  const { event } = props;
  const t = prepareTranslate();

  const { mutateAsync: companyMutate } = companyMutation.useFindById();
  const { mutateAsync: productMutate } = productMutation.useFindById();
  const { data: userData } = authQuery.useProfile();

  useEffect(() => {
    const processObj = {
      t,
      companyMutate,
      productMutate,
      e: event,
      userData,
    };

    if (event) {
      processSSEData(processObj);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  return null;
};

SSEHandler.propTypes = {
  event: PropTypes.object,
  t: PropTypes.func,
};

export default SSEHandler;
