export default {
  from: 'מתאריך',
  till: 'עד תאריך',
  number: 'מספר הסכם',
  agent: 'מספר סוכן',
  company: 'חברה מנהלת',
  branch: 'מוצר',
  branches: 'מוצרים',
  accordType: 'סוג הסכם',
  accordName: 'שם הסכם',
  submit: 'עדכן',
  commision: {
    agentFeeMonthlyAccumulation: 'עמלת סוכן נפרעים מהפקדה %',
    agentFeeMonthlyDeposit: 'עמלת סוכן נפרעים מצבירה %',
    agentFeeAnnualAccumulation: 'עמלת סוכן היקף מצבירה %',
    agentFeeAnnualDeposit: 'עמלת סוכן היקף מהפקדה %',
    employeeAgentCommission: 'עמלת סוכן שכיר %',
  },
};
