export default {
  clientNumber: 'מספר תיק',
  company: 'חברה מנהלת',
  hp: 'ח.פ',
  branch: 'מוצרים',
  agentCommission: 'עמלת סוכן',
  accordType: 'סוג הסכם',
  headers: {
    clients: 'תיקים',
  },
};
