import prepareTranslate from '@/helpers/dictionary';
import validations from '@/helpers/validation';
import {
  Button,
  Center,
  Container,
  Grid,
  LoadingOverlay,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  AGENT_STATUS,
  EMPLOYEE_TYPE,
  AGENT_TYPE,
  GENDER,
  PROCESS_NOTIFICATION_SCREENS,
} from '@/helpers/enums';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import PropTypes from 'prop-types';

function CreateAgentInProcess(props) {
  const {
    setPage,
    createAgentMutate,
    clientOrAgreement,
    setCreatedAgent,
  } = props;

  const t = prepareTranslate();

  const [error, setError] = useState(false);
  const [loading, { open, close }] = useDisclosure();

  const form = useForm({
    initialValues: {
      personalDetails: {
        children: [],
      },
      agreements: [],
      clients: [],
    },
    validate: {
      status: (value) => validations.required(value),
      agentType: (value) => validations.required(value),
      employeeType: (value) => validations.required(value),
      personalDetails: {
        firstName: (value) => validations.required(value),
        lastName: (value) => validations.required(value),
        gender: (value) => validations.required(value) || validations.required(value),
        uid: (value) => validations.required(value) || validations.uid(value),
        dob: (value) => validations.required(value),
      },
    },
  });

  const handleSubmit = async (values) => {
    open();

    try {
      const newAgent = await createAgentMutate(values);

      if (!newAgent) {
        setError(true);
      } else {
        setError(false);
        const agentString = `${form.getValues().personalDetails.firstName} ${form.getValues().personalDetails.lastName} (${form.getValues().personalDetails.uid})`;
        setPage(clientOrAgreement);
        setCreatedAgent({ id: newAgent.id, string: agentString });
      }
    } catch {
      setError(true);
    }

    close();
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <Container>
        <LoadingOverlay visible={loading} />
        <Center mb='xl'>
          <h1>
            הוספת סוכן
          </h1>
        </Center>
        <Grid gutter='lg' mb='lg'>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('components.createAgentInProcess.status')}
              data={AGENT_STATUS}
              withAsterisk
              key={form.key('status')}
              {...form.getInputProps('status')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('components.createAgentInProcess.agentType')}
              data={AGENT_TYPE}
              withAsterisk
              key={form.key('agentType')}
              {...form.getInputProps('agentType')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('components.createAgentInProcess.employeeType')}
              data={EMPLOYEE_TYPE}
              withAsterisk
              key={form.key('employeeType')}
              {...form.getInputProps('employeeType')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentInProcess.uid')}
              withAsterisk
              key={form.key('uid')}
              {...form.getInputProps('personalDetails.uid')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentInProcess.firstname')}
              withAsterisk
              key={form.key('firstName')}
              {...form.getInputProps('personalDetails.firstName')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentInProcess.lastname')}
              withAsterisk
              key={form.key('lastName')}
              {...form.getInputProps('personalDetails.lastName')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentInProcess.dob')}
              withAsterisk
              key={form.key('personalDetails.dob')}
              {...form.getInputProps('personalDetails.dob')}
              onChange={(e) => {
                const v = e.target.value
                  .replace(/^(\d\d)(\d)$/g, '$1/$2')
                  .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                  .replace(/[^\d/]/g, '');
                form.setFieldValue('personalDetails.dob', v);
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('components.createAgentInProcess.gender')}
              data={GENDER}
              key={form.key('gender')}
              withAsterisk
              {...form.getInputProps('personalDetails.gender')}
            />
          </Grid.Col>
        </Grid>
              <Text
                size='sm'
              >{t('components.createAgentInProcess.minimalDetails')}</Text>
        <Center>
          <Button mt='xl' type='submit'>
            צור סוכן
          </Button>
        </Center>
        {
          error
          && <Center mt='xl'>
            <Text c='red'>
              {t('components.createAgentInProcess.error')}
            </Text>
          </Center>
        }
      </Container>
    </form>
  );
}

CreateAgentInProcess.propTypes = {
  setPage: PropTypes.func.isRequired,
  createAgentMutate: PropTypes.func.isRequired,
  clientOrAgreement: PropTypes.oneOf(Object.values(PROCESS_NOTIFICATION_SCREENS)).isRequired,
  setCreatedAgent: PropTypes.func.isRequired,
};

export default CreateAgentInProcess;
