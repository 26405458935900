/* eslint-disable no-console */
import prepareTranslate from '@/helpers/dictionary';
import docsMutation from '@/api/mutitations/docs';
import { notifications } from '@mantine/notifications';
import useAccessToken from '@/hooks/useAccessToken';
import { Container } from '@mantine/core';
import Table from '@/components/Table';
import PageTemplate from '@/components/PageTemplate';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { routeWithParams } from '@/helpers/route';
import routes from '@/routes';
import { IconEye } from '@tabler/icons-react';
import validateQueries from './RemoteView.functions';

const ReportsList = () => {
  useAccessToken();

  const { mutateAsync: tableMutateAsync } = docsMutation.useExportTable();
  const [searchParams] = useSearchParams();
  const { reportId } = useParams();
  const Navigate = useNavigate();
  const t = prepareTranslate();

  const [columns, setColumns] = useState([]);

  const handleClickOnView = (value) => {
    const [agentId] = value.split('_');

    const queryValue = {
      month: ['7', '8'],
      year: ['2024'],
      productId: ['6617d5b437d01fce576453cb'],
      companyId: ['66045d75e376171abbb826a5'],
      identifiers: [agentId],
      query: {},
    };

    Navigate(routeWithParams(routes.reportsView.path, { reportId }, queryValue));
  };

  // eslint-disable-next-line no-unused-vars
  const getdata = async (start, end, query) => {
    let returnData = { data: [], series: [] };

    const queryValues = Object.fromEntries([...searchParams]);
    let queryData = validateQueries(queryValues); // TODO This queries will come from (queries)

    if (!queryData) {
      console.error('Somthing went wrong with the query parameters');
      notifications.show({
        title: t('pages.reports.title'),
        message: t('pages.reports.notification.error'),
        color: 'red',
      });

      return returnData;
    }

    queryData = { exportRuleId: reportId, ...queryData };

    try {
      const { data: mutateData, count } = await tableMutateAsync(queryData);
      const { rows, columns: dataColumns } = mutateData;
      setColumns([
        ...dataColumns,
        {
          header: t('pages.reports.portfolio.columns.actions.title'),
          actions: [
            {
              title: t('pages.reports.portfolio.columns.actions.action1'),
              action: handleClickOnView,
              icon: <IconEye />,
              showLabel: true,
              isPrimary: true,
            },
          ],
        },
      ]);
      returnData = { data: rows, count };
    } catch {
      notifications.show({
        title: t('pages.reports.title'),
        message: t('pages.reports.notification.error'),
        color: 'red',
      });
    }

    return returnData;
  };

  return (
    <PageTemplate
    title={t('pages.reports.portfolio.title')}
    >
      <Container fluid>
        <Table
          getData={getdata}
          columns={columns}
          isColumnsActive={false}
          isExportActive={false}
        />
      </Container>
    </PageTemplate>
  );
};

export default ReportsList;
