import PropTypes from 'prop-types';
import { Paper } from '@mantine/core';
import styles from './style.module.css';

const Box = (props) => {
  const {
    title,
    subtitle,
    children,
    onClickHandler,
    h,
  } = props;

  return (
    <Paper p="lg" pt='xs' radius="md" shadow='0px 1px 8px 0px rgba(0, 0, 0, 0.15)' onClick={onClickHandler} h={h}>
      <div className={onClickHandler ? styles.box : ''}>
        <header>
          {title && <div className={styles.title}>{title}</div>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </header>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </Paper>
  );
};

Box.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
  onClickHandler: PropTypes.func,
  h: PropTypes.string,
};

export default Box;
