import { headers as defaultHeaders } from '@/config/axios';
import axiosRequest from '.';

const createAndDelete = async (data) => (
  axiosRequest({
    data,
    url: '/v1/processInitiator/createAndDelete',
    method: 'POST',
    hders: defaultHeaders,
  })
);

export {
  createAndDelete,
};
